import React from 'react';
import Placeholder from "../../../js/extensions/Placeholder";

export interface PRTabProps {
    Label: string;
    placeholder?: boolean;
}

export default class PRTab extends React.Component<PRTabProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div  className="pr-tab">
            <Placeholder placeholderKey={'LexusTab'} isDynamic={true} placeholder={this.props.placeholder}>
                {this.props.children}
            </Placeholder>
        </div>);
    }
}