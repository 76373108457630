import React from 'react';
import PRButton from "../../PageContent/PRButton/PRButton";
import PRListHeader from "../../PageContent/PRListHeader/PRListHeader";
import PRAddToCart from "../../PageContent/icons/PRAddToCart";
import { CookieHelper } from "../../../services/cookieHelper";
import { Notification } from "../../Notification/Notification";
import "./PRItemList.scss";

export interface PRItemListProps {
    data: {
        Header: string;
        Items: Array<{
            Title: string;
            Copy: string;
            Type: string;
            Id: string;
        }>
    }
}

interface PRItemListState {
    showNotification: boolean;
}

export default class PRItemList extends React.Component<PRItemListProps, PRItemListState> {
    constructor(props) {
        super(props);

        this.state = {
            showNotification: false
        };
    }

    render() {
        return (
            <div className="item-list">
                <PRListHeader header={this.props.data.Header}>
                    {!!this.props.data.Items && !!this.props.data.Items.length && <div>
                        <Notification key="add-to-cart-notification"
                            message="You added these items to your basket"
                            open={this.state.showNotification}
                            onClose={this.handleNotificationClose} />
                        <PRButton data={{ type: 'tertiary' }} className="orange" onClick={this.addAllToCart}>Add All</PRButton>
                    </div>}
                </PRListHeader>
                {this.props.data.Items.map((item, $index) => {
                    return (
                        <div key={$index} className="item-container">
                            <div className="item-content">
                                <span className="item-number">{$index + 1}</span>
                                <div className="item-title">
                                    <span>{item.Title}</span>
                                </div>
                            </div>
                            <PRAddToCart className="item-action-item" cartItem={{ title: item.Title, type: item.Type, id: item.Id }}></PRAddToCart>
                        </div>
                    )
                })}
            </div>
        )
    }

    addAllToCart = () => {
        let itemsToAdd = this.props.data.Items.map((item) => {
            return { id: item.Id, title: item.Title, type: item.Type };
        });

        if (itemsToAdd.length) {
            let cookieHelper = new CookieHelper();
            let cookie = cookieHelper.getCookie("prcart");
            let items: Array<any> = cookie ? JSON.parse(cookie) : [];
            itemsToAdd.forEach(itemToAdd => {
                if (items.filter(item => item.id === itemToAdd.id).length === 0) {
                    items.push(itemToAdd);
                }
            });
            cookieHelper.setCookie("prcart", items);

            this.setState({ showNotification: true });
        }
    };

    handleNotificationClose = () => {
        this.setState({ showNotification: false });
    };
}