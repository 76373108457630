export default class Constants {
    static readonly MediaFolders = {
        Contacts: "{73A86E4D-638E-434A-8791-BB4D3D9F24CF}",
        Thumbnails: "{091D5637-58EF-46DC-A3F0-222EB1A8772F}",
        Banners: "{240754CF-DBCF-4022-B2DB-DF1414CCC59D}",
        PressRoomImages: "{A333AA10-E1BB-49A5-8304-8DDFD0663BB7}",
        PressRoomVideos: "{635AAF9F-3B97-4E7A-B25A-7918C1C1C6FE}",
        Other: "{E8092454-D5C0-4ACA-8111-9E1310F5DAB7}"
    }

    static readonly DateFormat = "DD/MM/YYYY";
    static readonly TimeStampFormat = "h:mm:ss A DD-MM-YYYY";
    static readonly ImageDimensions = {
        Banner: { width: 1600, height: 587 },
        Thumbnail: { width: 629, height: 350 },
        Contact: { width: 262, height: 262 }
    }

    static readonly Links = {
        PressReleaseForm: "/admin-dashboard/admin-pressrelease",
        PressKitForm: "/admin-dashboard/admin-presskit",
        PressReleaseDashboard: "/admin-dashboard/?Type=PressRelease",
        PressKitDashboard: "/admin-dashboard/?Type=PressKit",
        PressReleasePreview: "/PressRelease?pr-code="
    };

    constructor() {
    }
}