import React from "react";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { PressRoomApi } from "../../../../services/pressRoomApi";
import { PRIcon } from "../../../PRIcon/PRIcon";
import { DeleteIcon } from "../../../PRIcon/Icons";
import PRButton from "../../../PageContent/PRButton/PRButton";
import "./DeleteItem.scss";

interface IDeleteItemProps {
  itemType: string;
  itemId: string;
  onDelete?: Function;
}

interface IDeleteItemState {
  confirmationOpen: boolean;
  linkedPressKits: Array<{ Id: string, Title: string }>;
}

export class DeleteItem extends React.Component<IDeleteItemProps, IDeleteItemState> {
  constructor(props: IDeleteItemProps) {
    super(props);

    this.state = {
      confirmationOpen: false,
      linkedPressKits: []
    };
  }

  deleteItem = async () => {
    this.handleClose();

    let api = new PressRoomApi();
    if (this.props.itemType === "Image" || this.props.itemType === "Video") {
      await api.deleteMedia(this.props.itemId);
    }
    else if (this.props.itemType === "Press Release") {
      await api.deletePressRelease(this.props.itemId);
      this.state.linkedPressKits.map(async pressKit => {
        await api.deletePressKit(pressKit.Id);
      });
    }
    else if (this.props.itemType === "Press Kit") {
      await api.deletePressKit(this.props.itemId);
    }

    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  handleClickOpen = async (e) => {
    e.stopPropagation();

    if (this.props.itemType === "Press Release") {
      let api = new PressRoomApi();
      let response = await api.getPressKitsForPressRelease(this.props.itemId);
      if (response.success && response.data && response.data.length) {
        let pressKitsToDelete = response.data.filter(pressKit => { return pressKit.TotalNumberOfReleases === 1 });
        this.setState({ linkedPressKits: pressKitsToDelete });
      }
    }

    this.setState({ confirmationOpen: true });
  };

  handleClose = () => {
    this.setState({ confirmationOpen: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="card-action-icon" onClick={this.handleClickOpen}>
          <PRIcon className="delete-icon">
            <DeleteIcon />
          </PRIcon>
        </div>
        <Dialog
          className="delete-item-dialog"
          classes={{ root: "dialog-root", paper: "dialog-paper" }}
          open={this.state.confirmationOpen}
          onClose={this.handleClose}
        >
          <DialogTitle classes={{ root: "dialog-title" }}>Delete {this.props.itemType}</DialogTitle>
          <DialogContent>
            <DialogContentText classes={{ root: "dialog-content-text" }}>

              {this.state.linkedPressKits.length > 0 ?
                <div>
                  This press release is also used in the following press kits:
                <ul>
                    {this.state.linkedPressKits.map(pressKit => {
                      return <li key={pressKit.Id}>{pressKit.Title}</li>
                    })}
                  </ul>
                  By deleting it, it will also remove these press kits.
                </div>
                :
                "Are you sure you want to delete this item?"}

            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: "dialog-actions" }}>
            <PRButton data={{ type: 'primary' }} onClick={this.deleteItem}>Delete</PRButton>
            <PRButton data={{ type: 'secondary' }} onClick={this.handleClose}>Cancel</PRButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
