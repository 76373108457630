import React from 'react';
import "./PRMemberCard.scss";

export interface PRMemberCardProps {
    data: {
        Image: string;
        Name: string;
        Position: string;
        Url?:string;
    }
}

export default class PRMemberCard extends React.Component<PRMemberCardProps, {}> {
    constructor(props) {
        super(props);
    }

    getSrc = () => {
        let strings = this.props.data.Image.split(' ');
        let src = '';
        strings.forEach((value) => {
            if(value.indexOf('src=') > -1) {
                src = value.substring(value.indexOf('"'), value.lastIndexOf('"'));
            }
        });
        return src;
    }

    render() {
        let isEditing = this.props.data.Image.indexOf('<input') > -1;
        //let memberImage = {backgroundImage: "url(" +this.props.data.Image+")"};
        if (this.props.data.Url == null)
        {
            return (
                <div className="pr-member-card">
                {isEditing && 
                    <div className="pr-member-card-image" dangerouslySetInnerHTML={{__html: this.props.data.Image}}>
                    </div>
                }
                {!isEditing &&
                    <div className="pr-member-card-image bg-img" style={{backgroundImage: "url("+this.getSrc()+")"}}></div>
                }
                
                <div className="pr-member-card-copy">
                    <h3 dangerouslySetInnerHTML={{__html: this.props.data.Name}}></h3>
                    <div className="pr-member-card-position" dangerouslySetInnerHTML={{__html: this.props.data.Position}}></div>
                </div>
            </div>
            );
        }
        else{
            return (
                <div className="pr-member-card">
                <a className="pr-member-card-link" href={this.props.data.Url}>
                    {isEditing && 
                        <div className="pr-member-card-image" dangerouslySetInnerHTML={{__html: this.props.data.Image}}>
                        </div>
                    }
                    {!isEditing &&
                        <div className="pr-member-card-image bg-img" style={{backgroundImage: "url("+this.getSrc()+")"}}></div>
                    }
                    
                    <div className="pr-member-card-copy">
                        <h3 dangerouslySetInnerHTML={{__html: this.props.data.Name}}></h3>
                        <div className="pr-member-card-position" dangerouslySetInnerHTML={{__html: this.props.data.Position}}></div>
                    </div>
                </a>
            </div>
            );
        }
    }
}