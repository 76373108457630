import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import "./scaffolding.scss";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export interface PRMainLayoutProps {
  placeholder?: boolean;
  width: Breakpoint;
}

class PRMainLayout extends React.Component<PRMainLayoutProps, {}> {
  static propTypes = {
    width: PropTypes.string.isRequired
  };

  render() {
    let className = "PRMainLayout " + this.props.width;

    return (
      <div className={className}>
        <Placeholder
          placeholderKey={"LexusMain"}
          isDynamic={true}
          placeholder={this.props.placeholder}
        >
          {this.props.children}
        </Placeholder>
      </div>
    );
  }
}

export default withWidth()(PRMainLayout);
