import React, { PureComponent, CSSProperties } from "react";
import "./PRIcon.scss";

export interface IPRIconProps {
  color?: string;
  className?: string;
  side?: string;
  animate?: string;
  isAnimate?: boolean;
  style?: CSSProperties;
  onClick?: Function;
}

export class PRIcon extends PureComponent<IPRIconProps> {
  render() {
    let {
      className,
      color,
      side,
      animate,
      isAnimate,
      onClick,
      ...restProps
    } = this.props;

    className = `PRIcon ${className ? className : ""} ${color ? color:""} ${
      isAnimate ? "iconAnimateWrapper" : ""
    }`;

    let style;
    if (side) {
      if (side == "left") {
        style = {
          marginRight: "8px"
        };
      } else if (side == "right") {
        style = {
          marginLeft: "8px"
        };
      } else if (side == "center") {
        style = {
          marginRight: "8px",
          marginLeft: "8px"
        };
      }
    }
    style = { ...style, ...this.props.style };
    if (animate) {
      return (
        <div className="iconAnimateWrapper">
          <div className={className} style={style} {...restProps}>
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div className={className} style={style}>
          {this.props.children}
        </div>
      );
    }
  }
}
