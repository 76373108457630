import React from "react";
import "./PRFooter.scss";
import withWidth from "@material-ui/core/withWidth";
import Placeholder from "../../../js/extensions/Placeholder";
import { LexusFooterLogo } from "../Images/Images";

export interface PRFooterProps {
  data: {
    BottomLinks: Array<{
      Url: string;
      Label: string;
      Icon: string;
    }>;
  };
  placeholder?: boolean;
  isSitecore?: boolean;
  width?: string;
}

class PRFooter extends React.Component<PRFooterProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    let currentYear = (new Date()).getFullYear();
    let copyright = `© ${currentYear} Toyota Corporation Australia. All rights reserved.`;
    return (
      <div className={`pr-footer ${this.props.width}`}>
        <div className="pr-footer-main">
          <div className="pr-footer-main-container">
            <div className="footer-logo">
              <LexusFooterLogo />
            </div>
            <div className="footer-content">
              <Placeholder
                placeholderKey={"LexusRowContainer"}
                isDynamic={true}
                placeholder={this.props.placeholder}
              >
                {this.props.children}
              </Placeholder>
            </div>
          </div>
        </div>
        <div className="pr-footer-terms">
          <div className="pr-footer-terms-container">
            <div className="terms">
              {this.props.data.BottomLinks.map((linkItem, $index) => {
                return (
                  <div className="terms-link" key={"PRFooter-term-" + $index}>
                    <a href={linkItem.Url}><span dangerouslySetInnerHTML={{ __html: linkItem.Label }}></span></a>
                    {
                      $index !== this.props.data.BottomLinks.length - 1 &&
                      <span className="divider">|</span>
                    }
                  </div>
                );
              })}
            </div>
            <div className="copyright">{copyright}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(PRFooter);
