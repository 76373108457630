import React from "react";
import withWidth from "@material-ui/core/withWidth";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRCheckbox from "../../PageContent/Form/Fields/PRCheckbox/PRCheckbox";
import "./PRDownload.scss";
import {
  CloseIcon,
  DownloadIcon
} from "../../PRIcon/Icons";
import { ItemType } from "../../PageContent/ItemType/ItemType";
import { PressRoomApi } from "../../../services/pressRoomApi";
import { CookieHelper } from "../../../services/cookieHelper";

interface IDownloadItem {
  Id: string;
  FileName: string;
  DateAdded: string;
  FileSize: string;
  Dimensions: string;
  Format: string;
  FileType: string;
}

interface IPRDownloadProps {
  data: {
    DownloadItems: Array<IDownloadItem>;
  };
  width?: string;
}

interface IPRDownloadState {
  selectedIds: Array<string>;
  allSelected: boolean;
  downloadItems: Array<IDownloadItem>;
}

class PRDownload extends React.Component<IPRDownloadProps, IPRDownloadState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: [],
      allSelected: false,
      downloadItems: this.props.data.DownloadItems
    };
  }

  onItemSelectionChange = (value, id) => {
    if (this.state.selectedIds.indexOf(id) > -1) {
      if (!value) {
        var array = [...this.state.selectedIds];
        var index = array.indexOf(id);
        array.splice(index, 1);
        this.setState({ selectedIds: array });
      } else {
        this.setState(prevState => ({
          selectedIds: [...(prevState as any).selectedIds, id]
        }));
      }
    } else {
      if (value) {
        this.setState(prevState => ({
          selectedIds: [...(prevState as any).selectedIds, id]
        }));
      }
    }
  };

  onAllSelection = () => {
    if (this.state.allSelected) {
      this.setState({ selectedIds: [], allSelected: false });
    } else {
      var array = this.props.data.DownloadItems.map(item => {
        return item.Id;
      });
      this.setState({ selectedIds: array, allSelected: true });
    }
  };

  deleteItemsFromCookie = ids => {
    let cookieHelper = new CookieHelper();
    let cookie = cookieHelper.getCookie("prcart");
    let items = cookie ? JSON.parse(cookie) : [];
    let index = -1;
    ids.forEach(id => {
      index = -1;
      items.forEach((element, $index) => {
        if (element.id === id) {
          index = $index;
        }
      });
      if (index !== -1) {
        items.splice(index, 1);
      }
    });

    cookieHelper.setCookie("prcart", items);
  };

  deleteSelectedItems = ids => {
    var selectedArray = [...this.state.selectedIds];
    ids.forEach(id => {
      var index = selectedArray.indexOf(id);
      selectedArray.splice(index, 1);
    });
    this.setState({ selectedIds: selectedArray });
  };

  deleteDownloadItems = ids => {
    let allItems = [...this.state.downloadItems];
    ids.forEach(id => {
      var index = -1;
      allItems.forEach((element, $index) => {
        if (element.Id === id) {
          index = $index;
        }
      });
      if (index !== -1) {
        allItems.splice(index, 1);
      }
    });

    this.setState({ downloadItems: allItems });
  };

  deleteSelected = ids => {
    if (!ids.length) return;
    this.deleteItemsFromCookie(ids);
    this.deleteSelectedItems(ids);
    this.deleteDownloadItems(ids);
  };

  downloadSelected = ids => {
    if (!ids.length) return;
    let api = new PressRoomApi();
    api.downloadFiles(ids);
  };

  render() {
    return (
      <div className="pr-download">
        <div className="pr-download-container">
          <div className="download-content-container">
            {this.props.width !== "sm" && this.props.width !== "xs" && (
              <table>
                <tbody>
                  <tr className="header">
                    <th>
                      <button onClick={this.onAllSelection}>
                        {this.state.allSelected ? "Deselect all" : "Select all"}
                      </button>
                    </th>
                    <th className="file">Filename</th>
                    <th>Date added</th>
                    <th>Size</th>
                    <th>Dimensions</th>
                    <th>Format</th>
                    <th />
                  </tr>
                  {this.state.downloadItems.map((item, $index) => {
                    return (
                      <tr key={$index}>
                        <td>
                          <PRCheckbox
                            checked={
                              this.state.selectedIds.indexOf(item.Id) > -1
                            }
                            onChange={value => {
                              this.onItemSelectionChange(value, item.Id);
                            }}
                          />
                        </td>
                        <td>
                          <div className="file-name">
                            <ItemType type={item.FileType} />
                            <span>{item.FileName}</span>
                          </div>
                        </td>
                        <td>
                          {item.DateAdded}
                        </td>
                        <td>
                          {item.FileSize}
                        </td>
                        <td>
                          {item.Dimensions}
                        </td>
                        <td>
                          {item.Format}
                        </td>
                        <td>
                          <div
                            className="delete-container"
                            onClick={() => {
                              this.deleteSelected([item.Id]);
                            }}
                          >
                            <PRIcon>
                              <CloseIcon />
                            </PRIcon>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {(this.props.width === "sm" || this.props.width === "xs") &&
              this.state.downloadItems.map((item, $index) => {
                return (
                  <div key={$index} className="download-card-container">
                    <div className="download-card-content">
                      <div className="action-container">
                          <PRCheckbox
                            checked={
                              this.state.selectedIds.indexOf(item.Id) > -1
                            }
                            onChange={value => {
                              this.onItemSelectionChange(value, item.Id);
                            }}
                          />
                          <ItemType type={item.FileType} />
                          <span>{item.DateAdded}</span>
                        <div
                          className="delete-container"
                          onClick={() => {
                            this.deleteSelected([item.Id]);
                          }}
                        >
                          <PRIcon>
                            <CloseIcon />
                          </PRIcon>
                        </div>
                      </div>
                      <div className="file-name">{item.FileName}</div>
                      <div className="detail-row">
                        <div className="field">Size:</div>
                        <div className="value">{item.FileSize}</div>
                      </div>
                      <div className="detail-row">
                        <div className="field">Format:</div>
                        <div className="value">{item.Format}</div>
                      </div>
                      <div className="detail-row">
                        <div className="field">Dimensions:</div>
                        <div className="value">{item.Dimensions}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="download-action-container">
            <div
              className={this.state.selectedIds.length ? "download-action" : "download-action disabled"}
              onClick={() => { this.downloadSelected(this.state.selectedIds); }}>
              <div className="icon-container">
                <PRIcon>
                  <DownloadIcon />
                </PRIcon>
              </div>
              <span>Download selected</span>
            </div>
            <div
              className={this.state.selectedIds.length ? "download-action" : "download-action disabled"}
              onClick={() => { this.deleteSelected(this.state.selectedIds); }}>
              <div className="icon-container">
                <PRIcon className="close-icon">
                  <CloseIcon />
                </PRIcon>
              </div>
              <span>Delete selected</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(PRDownload);
