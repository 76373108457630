import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import "./scaffolding.scss";
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

export interface PRRowProps {
    placeholder?: boolean;
    width: string;
}

 class PRRow extends React.Component<PRRowProps, {}> {

    static propTypes = {
        width: PropTypes.string.isRequired
    };
    render() {
         
        return (
            <div className="pr-row">
                    <Placeholder placeholderKey={'LexusRow'} isDynamic={true} placeholder={this.props.placeholder}>
                        {this.props.children}
                    </Placeholder>
            </div>
        );
    }
}

export default withWidth()(PRRow);