import React from "react";
import "./PRCardList.scss";
import PRButton from "../PRButton/PRButton";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import SearchResults from "../../SearchResults/SearchResults";

interface PRCardListProps {
  width: string;
  data?: {
    Type: string;
    CardList?: Array<{
      Image?: string;
      Date?: string;
      Title?: string;
      Copy?: string;
      LinkUrl?: string;
      Type?: string;
      VideoUrl?: string;
    }>;
    CtaLink?: string;
  };
}

class PRCardList extends React.Component<PRCardListProps, {}> {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="card-list">
        <SearchResults
          items={this.props.data.CardList}
          actions={{ addToCart: true, download: true }}
          includePreview={this.props.data.Type === "Image" || this.props.data.Type === "Video"}
        />
        {this.props.data && this.props.data.CtaLink && (
          <PRButton className="view-all"
            data={{ type: "primary", link: this.props.data.CtaLink }}
          >
            View All
            </PRButton>
        )}
      </div>
    );
  }
}

export default withWidth()(PRCardList);
