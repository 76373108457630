export class CookieHelper {
    getCookie = (name: string) => {
        name = name + "=";
        let result: string;
        let cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            cookie = cookie.trim();
            if (cookie.indexOf(name) === 0) {
                result = cookie.substring(name.length, cookie.indexOf("expires="));
            }
        });
        return result;
    };

    setCookie = (name: string, value: any) => {
        let date = new Date();
        document.cookie =
            name + "=" +
            JSON.stringify(value || "") +
            "expires=" +
            date.setTime(date.getTime() + 60 * 60 * 1000) +
            "; path=/";
    };
}