import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import Popover from "@material-ui/core/Popover";
import "./PRMenuCart.scss";
import AliceCarousel from "react-alice-carousel";
import PRButton from "../PRButton/PRButton";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { LeftArrowIcon, RightArrowIcon, CloseIcon, DownloadIcon } from "../../PRIcon/Icons";
import { ItemType } from "../ItemType/ItemType";
import { CookieHelper } from "../../../services/cookieHelper";

export interface PRMenuCartProps {
  width: Breakpoint;
  className?: string;
  data: {
    DownloadCartLink?: string;
  };
}

interface PRMenuCartState {
  anchorEl: any;
  isOpen: boolean;
  currentIndex: number;
  items: Array<any>;
  opened: boolean;
}

class PRMenuCart extends React.Component<PRMenuCartProps, PRMenuCartState> {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      isOpen: false,
      currentIndex: 0,
      items: [],
      opened: false
    };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  responsive = {
    0: { items: 1 },
    600: { items: 1 },
    1024: { items: 1 }
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, isOpen: true });
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  };

  handleClose = () => {
    this.setState({ anchorEl: null, opened: false, isOpen: false });
  };

  handleOpened = () => {
    this.setState({ opened: true });
  }

  delete = id => {
    let cookieHelper = new CookieHelper();
    let cookie = cookieHelper.getCookie("prcart");
    let items = cookie ? JSON.parse(cookie) : [];
    let index = -1;
    items.forEach((element, $index) => {
      if (element.id === id) {
        index = $index;
      }
    });
    if (index !== -1) {
      items.splice(index, 1);
    }
    cookieHelper.setCookie("prcart", items);
  };

  componentDidMount() {
    setInterval(() => {
      let cookieHelper = new CookieHelper();
      let cookie = cookieHelper.getCookie("prcart");
      let items = cookie ? JSON.parse(cookie) : [];
      if (items.length !== this.state.items.length) {
        this.setState({ items: items });
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 20);
      }
    }, 500);
  }

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  render() {
    const { anchorEl } = this.state;
    let className =
      `cart other-item ${this.props.className || ""} ${this.props.width === "sm" || this.props.width === "xs" ? "xs" : ""} ${this.state.isOpen ? "is-open" : ""}`;

    let slides = [];

    for (let i = 0; i <= parseInt("" + this.state.items.length / 5, 10); i++) {
      let index = i * 5;

      let slideItem = [];

      for (let j = 0; j < 5 && index + j < this.state.items.length; j++) {
        let item = (
          <div key={index + j} className="item-container">
            <ItemType type={this.state.items[index + j].type} />
            <div className="item-content">
              {this.state.items[index + j].title}
            </div>
            <div className="close-button" onClick={() => this.delete(this.state.items[index + j].id)}>
              <PRIcon className="close-icon">
                <CloseIcon />
              </PRIcon>
            </div>
          </div>
        );

        slideItem.push(item);
      }

      slideItem.length > 0
        ? slides.push(
          <div key={index} className="slide-container">
            {slideItem}
          </div>
        )
        : null;
    }

    return (
      <div>
        <div
          className={className}
          aria-owns={this.state.anchorEl ? "cart-item-container" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <div className="bag-icon-container">
            <PRIcon className="bag-icon">
              {this.state.isOpen && (this.props.width === "sm" || this.props.width === "xs") ?
                <CloseIcon /> :
                <DownloadIcon />
              }
            </PRIcon>
            {!!this.state.items.length && <div className="cart-value-container">
              <span className="bag-value">{this.state.items.length}</span>
            </div>}
          </div>
        </div>

        <Popover
          id="cart-item-container"
          className={`cart-item-container ${this.props.width === "sm" || this.props.width === "xs" ? "xs" : ""}`}
          open={anchorEl != null && anchorEl.classList.contains("cart")}
          anchorEl={anchorEl ? anchorEl.parentElement : null}
          onClose={this.handleClose}
          onEntered={this.handleOpened}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{ paper: "popover-paper" }}
          marginThreshold={0}
        >
          <div className="cart-container justify-content-center">
            <div className="header">
              <div>Downloads</div>            
              <div>
                <span>{this.state.items.length > 0 ? this.state.currentIndex + 1 : "0"}</span>
                <span className="slash">/</span>
                <span>{slides.length}</span>
              </div>
            </div>
            <div className="items-container">
              {this.state.opened && <AliceCarousel
                responsive={this.responsive}
                dotsDisabled={true}
                buttonsDisabled={true}
                slideToIndex={this.state.currentIndex}
                onSlideChanged={this.onSlideChanged}
              >
                {slides}
              </AliceCarousel>}
              <div className={`carousel-control-container ${slides.length > 1 ? "include-prev-next" : ""}`}>
                {slides.length > 1 && (
                  <div className="carousel-control prev" onClick={this.slidePrev}>
                    <PRIcon>
                      <LeftArrowIcon />
                    </PRIcon>
                  </div>
                )}
                {this.state.items.length ?
                  <PRButton data={{ type: "tertiary", link: this.props.data.DownloadCartLink }} className="centered view-all-button light">
                    View All
                  </PRButton> :
                  <div className="no-items">No items</div>
                }
                {slides.length > 1 && (
                  <div className="carousel-control next" onClick={this.slideNext}>
                    <PRIcon>
                      <RightArrowIcon />
                    </PRIcon>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

export default withWidth()(PRMenuCart);
