import * as React from "react";
import "./PRArticle.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRBodyCopy from "../PRBodyCopy/PRBodyCopy";
import { FacebookIcon, LinkShareIcon } from "../../PRIcon/Icons";
import moment from 'moment';

export interface ArticleProps {
  data?: {
    Date?: string;
    Social?: Array<{
      Type: string;
    }>;
    Title?: string;
    Body: string;
  };
}

export default class PRArticle extends React.Component<ArticleProps, {}> {
  render() {
    const socialTags =
      this.props.data && this.props.data.Social
        ? this.props.data.Social.map((social, $index) => {
          if (social.Type === "facebook") {
            return (
              <PRIcon>
                <FacebookIcon />
              </PRIcon>
            );
          } else if (social.Type === "link") {
            return (
              <PRIcon className="link-icon">
                <LinkShareIcon />
              </PRIcon>
            );
          }
        })
        : "";

    const date = moment(this.props.data.Date, "DD-MM-YYYY");

    return (
      <div className="pr-article">
        {this.props.data && this.props.data.Date && date && (
          <div className="pr-article-date">
            <span>{date.date()}</span>
            <span className="slash">/</span>
            <span>{date.month() + 1}</span>
            <span className="slash">/</span>
            <span>{date.year()}</span>
          </div>
        )}
        {this.props.data && this.props.data.Title && (
          <div
            className="article-title"
            dangerouslySetInnerHTML={{ __html: this.props.data.Title }}
          />
        )}
        {socialTags !== "" && (
          <div className="article-social-share">
            <span>Share:</span> {socialTags}
          </div>
        )}
        <PRBodyCopy data={{ BodyContent: this.props.data.Body }} />
      </div>
    );
  }
}
