import React from "react";
import Input from "@material-ui/core/Input";
import "./PRMenuSearch.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import { SearchIcon, CloseIcon } from "../../PRIcon/Icons";
import { Drawer, Popover } from "@material-ui/core";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface IPRMenuSearchProps {
  width: Breakpoint;
  data: {
    SearchUrl: string;
  };
}

interface IPRMenuSearchState {
  anchorEl: any;
  value: string;
  isOpen: boolean;
}

class PRMenuSearch extends React.Component<IPRMenuSearchProps, IPRMenuSearchState> {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      value: "",
      isOpen: false
    };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  onKeyUp = e => {
    if (e.key === "Enter") {
      this.goToRedirectUrl(e.target.value);
    }
  };

  goToRedirectUrl = value => {
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      this.props.data.SearchUrl +
      "?Search=1" +
      (value ? "&Query=" + value : "");
  };

  onChange = e => {
    this.setState({ value: e.target.value });
  };

  toggleOpen = () => {
    this.setState(state => { return { isOpen: !state.isOpen } });

  };

  search = () => {
    this.goToRedirectUrl(this.state.value);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, isOpen: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, isOpen: false });
  };

  render() {
    const { anchorEl } = this.state;

    if (this.props.width === "sm" || this.props.width === "xs") {
      return (
        <div>
          <div 
          
          className={`other-item search-item xs ${this.state.isOpen ? 'is-open' : ''}`}
          aria-owns={this.state.anchorEl ? "search-item-container" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          
          >
            <PRIcon className="search-icon">
              {
                this.state.isOpen ?
                  <CloseIcon /> :
                  <SearchIcon />
              }
            </PRIcon>
          </div>

          <Popover
          id="search-item-container"
          className="search-item-container"
          open={anchorEl != null && anchorEl.classList.contains("search-item")}
          anchorEl={anchorEl ? anchorEl.parentElement : null}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          classes={{ paper: "popover-paper" }}
          marginThreshold={0}
        >
          <div className="search-container justify-content-center">
          <div className="search-row">
              <Input
                id="search"
                type="search"
                className="search-field"
                placeholder="Search..."
                onKeyUp={this.onKeyUp}
                onChange={this.onChange}
                disableUnderline={true}
              />
              <div onClick={this.search}>
                <PRIcon className="search-icon">
                  <SearchIcon />
                </PRIcon>
              </div>
            </div>
          </div>
        </Popover>
        </div>);
    }
    else {
      return (<div className={`other-item search-item ${this.state.isOpen ? 'is-open' : ''}`}>
        {
          this.state.isOpen &&
          <PRIcon><SearchIcon /></PRIcon>
        }
        <Input
          id="search"
          type="search"
          className="search-field"
          placeholder="Search..."
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          disableUnderline={true}
        />
        <div onClick={this.toggleOpen}>
          <PRIcon className="search-icon">
            {
              this.state.isOpen ?
                <CloseIcon /> :
                <SearchIcon />
            }
          </PRIcon>
        </div>
      </div>)
    }
  }
}

export default withWidth()(PRMenuSearch);