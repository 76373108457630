import * as React from "react";
import "./PRLinksList.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRLink from "../PRLink/PRLink";
import Subscribe from "../Subscribe/Subscribe";
import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  TwitterIcon,
  MessageIcon
} from "../../PRIcon/Icons";

export interface PRLinksListProps {
  data: {
    Title: string;
    Type: string;
    Links: [
      {
        Url: string;
        Label: string;
        Icon: string;
      }
    ];
    SubscriptionData?: {
      HeaderLabel: string;
      HeaderIcon?: string;
      ApiUrl?: string;
      PrimaryBtnLabel?: string;
      SecondaryBtnLabel?: string;
      TermsAndConditionsLink: string;
    };
  };
}

export default class PRLinksList extends React.Component<PRLinksListProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const linkTags = this.props.data.Links.map((link, $index) => {
      if (this.props.data.Type == "links") {
        return <PRLink key={"listlink-link-" + $index} data={link} />;
      } else if (this.props.data.Type == "icons") {
        if (link.Icon != "newsletter") {
          return (
            <a href={link.Url} key={"listlink-link-" + $index}>
              <PRIcon className={link.Icon}>
                {link.Icon === "facebook" ? (
                  <FacebookIcon />
                ) : link.Icon === "instagram" ? (
                  <InstagramIcon />
                ) : link.Icon === "youtube" ? (
                  <YoutubeIcon />
                ) : link.Icon === "twitter" ? (
                  <TwitterIcon />
                ) : link.Icon === "newsletter" ? (
                  <MessageIcon />
                ) : (
                  ""
                )}
              </PRIcon>
            </a>
          );
        } else {
          return (
            <Subscribe
              key={"listlink-link-" + $index}
              data={this.props.data.SubscriptionData}
            />
          );
        }
      }
    });

    return (
      <div className="links-container">
        <h3 className="footer-title">{this.props.data.Title}</h3>
        <div className={this.props.data.Type}>{linkTags}</div>
      </div>
    );
  }
}
