import React from "react";
import "./Card.scss";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { CardActions } from "../../AdminPanel/CardActions/CardActions";
import PRButton from "../PRButton/PRButton";
import moment from 'moment';
import utils from "../../../services/utils";

interface ICardProps {
  data: {
    Id?: string;
    Image?: string;
    Date?: string;
    Title?: string;
    Copy?: string;
    LinkUrl?: string;
    Type?: string;
    VideoUrl?: string;
    Published?: boolean;
    Selected?: boolean;
  };
  width: string;
  onSelectionChange?: Function;
  onClick?: Function;
  actions?: {
    select?: boolean;
    addToCart?: boolean;
    download?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
  onEdit: Function;
  onDelete: Function;
  hideContent?: boolean;
  className?: string;
}

class Card extends React.Component<ICardProps> {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    let classname =
      "card-container " +
      this.props.width +
      (this.props.className ? " " + this.props.className : "");
    const date = moment(this.props.data.Date, "DD-MM-YYYY");
    return (
      <div className={classname} onClick={this.onClick}>
        <div className="card-image-container">
          <div
            className="image"
            style={{ backgroundImage: "url('" + (this.props.data.Image || utils.getEmptyImage()) + "')" }}
          />
          {this.props.actions && <CardActions
            actions={this.props.actions}
            item={this.props.data}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            onSelectionChange={this.props.onSelectionChange} />}
        </div>
        {!this.props.hideContent &&
          <React.Fragment>
            <div className="card-copy-container">
              {this.props.data.Type !== "Image" && this.props.data.Type !== "Video" && this.props.data.Published ?
                <a href={this.props.data.LinkUrl}><h3 className="card-title" dangerouslySetInnerHTML={{ __html: this.props.data.Title }} /></a> :
                <h3 className="card-title" dangerouslySetInnerHTML={{ __html: this.props.data.Title }} />}
              {this.props.data && this.props.data.Date && date && (
                <div className="card-date">
                  <span>{date.date()}</span>
                  <span className="slash">/</span>
                  <span>{date.month() + 1}</span>
                  <span className="slash">/</span>
                  <span>{date.year()}</span>
                </div>
              )}
              <p className="card-copy" dangerouslySetInnerHTML={{ __html: this.props.data.Copy }} />
              {this.props.width !== "xs" && this.props.data.Type !== "Image" && this.props.data.Type !== "Video" && this.props.data.Published &&
                <PRButton data={{ type: "tertiary", link: this.props.data.LinkUrl, newtab: this.props.actions.edit }}>Read More</PRButton>
              }
             {this.props.width !== "xs" && this.props.data.Type !== "Image" && this.props.data.Type !== "Video" && !this.props.data.Published && (
              <PRButton data={{ type: "tertiary", link: this.props.data.LinkUrl, newtab: true }}>Preview Draft</PRButton>)
             }
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default withWidth()(Card);
