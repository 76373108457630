import * as React from "react";

export class FacebookIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="23" viewBox="0 0 11 23">
        <path fill="#E4E1D7" fillRule="nonzero" d="M7.191 6.889V5.042c0-.886.595-1.098 1.025-1.098h2.616V0H7.23C3.223 0 2.326 2.92 2.326 4.817V6.89H0v4.605h2.35V23h4.665V11.494h3.45l.152-1.81.278-2.795H7.19z" />
      </svg>
    );
  }
}

export class InstagramIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <path fill="#E4E1D7" fillRule="nonzero" d="M16.335 11.72a4.616 4.616 0 0 0-4.616-4.616 4.621 4.621 0 0 0-4.615 4.615 4.616 4.616 0 0 0 9.23 0m1.35-6.935c-.648 0-1.17.523-1.17 1.17 0 .647.522 1.17 1.17 1.17.647 0 1.17-.523 1.17-1.17 0-.647-.523-1.17-1.17-1.17m-11.904-3h11.891a4 4 0 0 1 3.997 3.996v11.891a4 4 0 0 1-3.997 3.996H5.781a4 4 0 0 1-3.998-3.996V5.781A4.001 4.001 0 0 1 5.78 1.782M17.671.451H5.781C2.854.451.45 2.841.45 5.781v11.89C.451 20.597 2.855 23 5.781 23h11.89C20.61 23 23 20.597 23 17.671V5.781C23 2.84 20.61.45 17.671.45M5.967 11.719c0-3.175 2.577-5.765 5.752-5.765a5.768 5.768 0 0 1 5.765 5.765 5.76 5.76 0 1 1-11.517 0" />
      </svg>
    );
  }
}

export class YoutubeIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18">
        <path fill="#E4E1D7" fillRule="nonzero" d="M15.346 8.398l-6.22 3.352V5.019l2.817 1.533 3.403 1.846zm7.43-4.538s-.237-1.658-.923-2.381C20.981.53 19.996.519 19.56.456 16.343.22 11.52.22 11.52.22h-.027s-4.81 0-8.028.236C3.005.52 2.032.531 1.16 1.48c-.686.723-.922 2.38-.922 2.38S0 5.805 0 7.725v1.832c0 1.945.238 3.865.238 3.865s.236 1.657.922 2.393c.872.96 2.02.923 2.543 1.022 1.833.187 7.816.25 7.816.25s4.824-.025 8.041-.25c.436-.062 1.421-.062 2.293-1.022.686-.736.923-2.393.923-2.393S23 11.5 23 9.556V7.724c0-1.92-.224-3.864-.224-3.864z" />
      </svg>
    );
  }
}

export class TwitterIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 50 50" version="1.1">
        <path
          d="M49.8255415,9.82905604 C48.0280375,10.606784 46.0726071,11.1432576 44.0084535,11.4107008 C46.1257783,10.1512576 47.7336119,8.22122242 48.4859447,5.86184961 C46.5281335,7.01495042 44.3306551,7.84584962 42.025247,8.30137603 C40.199967,6.34515201 37.5485494,5.1380864 34.5987382,5.1380864 C29.0229045,5.1380864 24.4644661,9.61478404 24.4644661,15.1104641 C24.4644661,15.9151745 24.6001717,16.6659201 24.7604789,17.3896833 C16.3165748,16.9881217 8.83610119,13.0201217 3.84911875,6.96098562 C2.99203074,8.46247683 2.45635074,10.1512576 2.45635074,11.9741569 C2.45635074,15.4596481 4.27924995,18.5150081 6.95923717,20.2855297 C5.29823236,20.2585473 3.74277635,19.8014337 2.37619714,19.0514817 L2.37619714,19.1856001 C2.37619714,20.4720257 2.61745154,21.6521089 3.07218434,22.7774337 C4.30702595,25.9153282 7.12113157,28.2731138 10.5264692,28.9437058 C9.64001799,29.1857538 8.78292999,29.3190786 7.84568838,29.3190786 C7.20207878,29.3190786 6.55767557,29.2381314 5.94025477,29.1048066 C7.25524998,33.072013 10.981202,35.9670658 15.405522,36.0742018 C11.9192372,38.7549827 7.57665798,40.3366275 2.83172354,40.3366275 C2.00003074,40.3366275 1.22388993,40.2826627 0.392990723,40.2025091 C4.89587716,43.0182019 10.2304564,44.6800003 15.941202,44.6800003 C31.7846326,44.6800003 41.568927,33.714829 44.1147959,22.3211137 C44.5719095,20.2855297 44.7845943,18.2745473 44.7845943,16.2905473 L44.7845943,15.0041217 C46.7424055,13.5819905 48.4859447,11.8130561 49.8255415,9.82905604"
          id="path-1"
        />
      </svg>
    );
  }
}

export class MessageIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M24.413 8H7.587L16 15.649 24.413 8zM26 9.26l-10 9.091-10-9.09V24h20V9.26zM28 6v20H4V6h24z" />
      </svg>
    );
  }
}

export class ArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <polygon points="21.97 58.8 20.78 57.44 46.48 34.94 20.99 12.56 22.18 11.2 49.22 34.94 21.97 58.8" />
      </svg>
    );
  }
}

export class LeftArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#CA4B19" fillRule="nonzero" d="M18 8l1.887 1.887L13.773 16l6.114 6.113L18 24l-8-8z" />
      </svg>
    );
  }
}

export class RightArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#CA4B19" fillRule="nonzero" d="M13.887 8L12 9.887 18.113 16 12 22.113 13.887 24l8-8z" />
      </svg>
    );
  }
}

export class UpArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#CA4B19" fillRule="nonzero" d="M24 19l-1.887 1.887L16 14.773l-6.113 6.114L8 19l8-8z" />
      </svg>
    );
  }
}

export class DownArrowIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#CA4B19" fillRule="nonzero" d="M24 13.887l-8 8-8-8L9.887 12 16 18.113 22.113 12z" />
      </svg>
    );
  }
}

export class ArrowDownIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <title>toyotaIcon_arrowDown</title>
        <polygon points="35.09 50.67 0 21.09 1.49 19.33 35.09 47.66 68.52 19.55 70 21.32 35.09 50.67" />
      </svg>
    );
  }
}

export class SearchIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M28 26.55L26.55 28l-7.19-7.19 1.45-1.45L28 26.55zM14.25 24.5C8.59 24.5 4 19.911 4 14.25 4 8.59 8.59 4 14.25 4 19.911 4 24.5 8.59 24.5 14.25c0 5.661-4.589 10.25-10.25 10.25zm0-2.05a8.2 8.2 0 1 0 0-16.4 8.2 8.2 0 0 0 0 16.4z" />
      </svg>
    );
  }
}

export class MenuIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M7 22h18v-2H7v2zm0-5h18v-2H7v2zm0-7v2h18v-2H7z" />
      </svg>
    );
  }
}

export class DownloadIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M17 16.586l5-5L23.414 13 16 20.414 8.586 13 10 11.586l5 5V6h2v10.586zM25 24v-6h2v8H5v-8h2v6h18z" />
      </svg>
    );
  }
}

export class PlusIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#CA4B19" fillRule="nonzero" d="M25 17h-8v8h-2v-8H7v-2h8V7h2v8h8z" />
      </svg>
    );
  }
}

export class BagIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M48.92,12.42C48.36,5.23,42.34.54,35,.54S21.64,5.23,21.08,12.42H0v46H70v-46ZM35,2.74c6.12,0,11.17,3.7,11.72,9.68H23.28C23.83,6.44,28.88,2.74,35,2.74Zm32.8,53.5H2.2V14.62H67.8Z" />
      </svg>
    );
  }
}

export class ImageIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M27 24.556V7.444C27 6.1 25.9 5 24.556 5H7.444A2.452 2.452 0 0 0 5 7.444v17.112C5 25.9 6.1 27 7.444 27h17.112C25.9 27 27 25.9 27 24.556zm-15.278-6.723l3.056 3.68L19.056 16l5.5 7.333H7.444l4.278-5.5z" />
      </svg>
    );
  }
}

export class DocumentIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M20.414 4L27 10.586V28H5V4h15.414zM19 6H7v20h18V12h-6V6zm3 14v2H10v-2h12zm0-5v2H10v-2h12zm-6-5v2h-6v-2h6z" />
      </svg>
    );
  }
}

export class CloseIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M25 8.813L23.187 7 16 14.187 8.813 7 7 8.813 14.187 16 7 23.187 8.813 25 16 17.813 23.187 25 25 23.187 17.813 16z" />
      </svg>
    );
  }
}

export class VideoIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M14 20.5l6-4.5-6-4.5v9zM16 4C9.376 4 4 9.376 4 16s5.376 12 12 12 12-5.376 12-12S22.624 4 16 4zm0 22c-5.512 0-10-4.488-10-10S10.488 6 16 6s10 4.488 10 10-4.488 10-10 10z" />
      </svg>
    );
  }
}

export class PenIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path
          d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
          id="path-1"
        />
      </svg>
    );
  }
}

export class LoctionIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M35,70c-5.72,0-7.77-5.91-7.86-6.16L13.85,32.57a22.86,22.86,0,0,1-2-9.41,23.16,23.16,0,1,1,44.64,8.65h0l-.33.77L42.83,63.91C42.77,64.1,40.64,70,35,70ZM35,2a21.17,21.17,0,0,0-19.33,29.8L29,63.13c.09.27,1.72,4.89,6,4.89s5.91-4.63,6-4.83l13.35-31.4.31-.73a21,21,0,0,0,1.53-7.9A21.2,21.2,0,0,0,35,2Z" />
        <path d="M35,32.69a8.3,8.3,0,1,1,8.3-8.3A8.31,8.31,0,0,1,35,32.69Zm0-15a6.69,6.69,0,1,0,6.69,6.68A6.69,6.69,0,0,0,35,17.71Z" />
      </svg>
    );
  }
}

export class PhoneIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M10.62 14.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V24c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
      </svg>
    );
  }
}

export class LinkShareIcon extends React.PureComponent {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 70"
      >
        <path d="M61.94,14.5a11.82,11.82,0,0,0-3.47-8.42L55.86,3.47A11.94,11.94,0,0,0,39,3.47L22.37,20.14A11.88,11.88,0,0,0,22,36.59l-10.5,10.5a11.94,11.94,0,0,0,0,16.83l2.61,2.61a11.94,11.94,0,0,0,16.83,0L47.63,49.86A11.88,11.88,0,0,0,48,33.41l10.5-10.5A11.81,11.81,0,0,0,61.94,14.5Zm-12.48,27a10.17,10.17,0,0,1-3,7.25L29.81,65.37a10.3,10.3,0,0,1-14.51,0l-2.61-2.61a10.3,10.3,0,0,1,0-14.51L23.17,37.77,25,39.57a11.9,11.9,0,0,0,16.82,0l5-5A10.15,10.15,0,0,1,49.46,41.45ZM24.33,36.61l5-5a10.26,10.26,0,0,1,14.5,0l1.81,1.8-5,5a10.26,10.26,0,0,1-14.5,0Zm22.5-4.38L45,30.43a11.9,11.9,0,0,0-16.82,0l-5,5a10.25,10.25,0,0,1,.34-14.13L40.19,4.63a10.3,10.3,0,0,1,14.51,0l2.61,2.61a10.3,10.3,0,0,1,0,14.51Z" />
      </svg>
    );
  }
}

export class WebIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 56 56" version="1.1">
        <path
          d="M28,56 C12.536027,56 0,43.463973 0,28 C0,12.536027 12.536027,0 28,0 C43.463973,0 56,12.536027 56,28 C56,43.463973 43.463973,56 28,56 Z M28,54 C42.3594035,54 54,42.3594035 54,28 C54,13.6405965 42.3594035,2 28,2 C13.6405965,2 2,13.6405965 2,28 C2,42.3594035 13.6405965,54 28,54 Z M28,55 C19.0161563,55 12,42.8386623 12,28 C12,13.1613377 19.0161563,1 28,1 C36.9838437,1 44,13.1613377 44,28 C44,42.8386623 36.9838437,55 28,55 Z M28,53 C35.5846988,53 42,41.8801446 42,28 C42,14.1198554 35.5846988,3 28,3 C20.4153012,3 14,14.1198554 14,28 C14,41.8801446 20.4153012,53 28,53 Z M27,1 L29,1 L29,54 L27,54 L27,1 Z M52,39 L52,41 L4,41 L4,39 L52,39 Z M55,26.5 L55,28.5 L1,28.5 L1,26.5 L55,26.5 Z M51.684615,14 L51.684615,16 L5,16 L5,14 L51.684615,14 Z"
          id="Combined-Shape"
        />
      </svg>
    );
  }
}

export class PlayIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 70 70" version="1.1">
        <polygon
          id="Triangle-2"
          transform="translate(34.000000, 35.000000) rotate(90.000000) translate(-34.000000, -35.000000) "
          points="34 1 68 69 0 69"
        />
      </svg>
    );
  }
}

export class DeleteIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    );
  }
}

export class PictureIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
      </svg>
    );
  }
}

export class VideoCamIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
      </svg>
    );
  }
}

export class BookIcon extends React.PureComponent {
  render() {
    return (
      <svg viewBox="0 0 70 70" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-87.000000, -3921.000000)">
            <rect x="0" y="0" width="780" height="5448"></rect>
          </g>
          <g id="icons-/-press" fill="#9b9b9b">
            <path d="M0,59.126 L69.126,59.126 L69.126,10 L0,10 L0,59.126 Z M35.563,57.126 L67.126,57.126 L67.126,12 L35.563,12 L35.563,57.126 Z M2,57.126 L33.563,57.126 L33.563,12 L2,12 L2,57.126 Z M41.628,23.397 L61.191,23.397 L61.191,21.397 L41.628,21.397 L41.628,23.397 Z M41.628,31.897 L61.191,31.897 L61.191,29.897 L41.628,29.897 L41.628,31.897 Z M41.628,40.397 L61.191,40.397 L61.191,38.397 L41.628,38.397 L41.628,40.397 Z M41.628,48.897 L61.191,48.897 L61.191,46.897 L41.628,46.897 L41.628,48.897 Z M7.628,23.397 L27.191,23.397 L27.191,21.397 L7.628,21.397 L7.628,23.397 Z M7.628,31.897 L27.191,31.897 L27.191,29.897 L7.628,29.897 L7.628,31.897 Z M7.628,40.397 L27.191,40.397 L27.191,38.397 L7.628,38.397 L7.628,40.397 Z M7.628,48.897 L27.191,48.897 L27.191,46.897 L7.628,46.897 L7.628,48.897 Z" id="Fill-1"></path>
          </g>
        </g>
      </svg>
    );
  }
}

export class DescriptionIcon extends React.PureComponent {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
      </svg>
    );
  }
}

export class CalendarIcon extends React.PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#232429" fillRule="nonzero" d="M26 12V8h-2v2h-4V8h-8v2H8V8H6v4h20zm0 2H6v12h20V14zm-2-8h4v22H4V6h4V4h4v2h8V4h4v2z" />
      </svg>
    );
  }
}