import React from 'react';
import PRButton from "../../PageContent/PRButton/PRButton";
import PRListHeader from "../../PageContent/PRListHeader/PRListHeader";
import './PRMediaList.scss';
import Card from '../../PageContent/Card/Card';

interface PRMediaListProps {
    data: {
        CtaUrl: string;
        Items?: Array<{
            Id?: string;
            Image?: string;
            Date?: string;
            Title?: string;
            Copy?: string;
            LinkUrl?: string;
            Type?: string;
            VideoUrl?: string;
        }>;
    }
}

interface PRMediaListState {
    mediaCardListComponentExists: boolean;
}

const mediaCardListComponentId = "pr-media-card-list";

export default class PRMediaList extends React.Component<PRMediaListProps, PRMediaListState> {
    constructor(props: PRMediaListProps) {
        super(props);

        this.state = { mediaCardListComponentExists: false };
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        let mediaCardListComponent = document.getElementById(mediaCardListComponentId);
        if (mediaCardListComponent) {
            this.setState({ mediaCardListComponentExists: true });
        }
    };

    scrollToMediaCardListComponent = () => {
        window.scrollTo(0, document.getElementById(mediaCardListComponentId).getBoundingClientRect().top + (window.scrollY || window.pageYOffset) - 150);
    };

    render() {
        let cards = null;
        if (this.props.data.Items != null) {
            cards = this.props.data.Items.map((item, $index) => {
                let data = { ...item };
                return (
                    <Card
                        key={$index}
                        data={data}
                        actions={{ addToCart: true, download: true }}
                        hideContent>
                    </Card>
                );
            });
        }
        else {
            cards = this.props.children;
        }
        return (
            <div className="pr-media-list">
                <PRListHeader header="Media">
                    {this.state.mediaCardListComponentExists && <PRButton onClick={this.scrollToMediaCardListComponent} data={{ type: 'tertiary' }} className="orange" >View All</PRButton>}
                </PRListHeader>
                <div className="pr-media-card-container">
                    {cards}
                </div>
            </div>
        );
    }
}