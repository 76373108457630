import React from 'react';
import './PRContentCard.scss';
import PRButton from "../PRButton/PRButton";
import objectFitImages from 'object-fit-images';

export interface PRContentCardProps {
    data: {
        Id: string;
        Title: string;
        Image: string;
        Url: string;
    }
}

export default class PRContentCard extends React.Component<PRContentCardProps, {}> {
    private imageElement: HTMLImageElement;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        objectFitImages(this.imageElement);
    }

    render() { 
        return (
            <div className="content-card">
                <div className="content-card-image">
                    <img ref={image => (this.imageElement = image)} src={this.props.data.Image} />
                </div>
                <div className="content-card-content">
                    <div className="content-card-text">{this.props.data.Title}</div>
                    <div className="content-card-cta"><PRButton data={{type: 'tertiary', link: this.props.data.Url }}>Read More</PRButton></div>
                </div>
            </div>
        );
    }
}