import React from "react";
import withWidth from "@material-ui/core/withWidth";
import PRDropdown from "../Form/Fields/PRDropdown/PRDropdown";
import { Date } from "../Date/Date";
import PRTextField from "../Form/Fields/PRTextField/PRTextField";
import PRButton from "../PRButton/PRButton";
import { Categories } from '../../Categories/Categories';
import { PRIcon } from "../../PRIcon/PRIcon";
import "./PRFilter.scss";
import { UpArrowIcon, DownArrowIcon } from "../../PRIcon/Icons";
import { FormControlLabel, Radio } from "@material-ui/core";

interface IPRFilterProps {
  width?: string;
  label?: string;
  mediaType?: string;
  showAllOption?: boolean;
  showPaginationOptions?: boolean;
  showTypes?: boolean;
  filters?: {
    Query: string;
    SortType: string;
    DateFrom: string;
    DateTo: string;
    Page: number;
    PerPage: number;
    Type: string;
    CategoryId: string;
  };
  onFilterChange?: Function;
}

interface IPRFilterState {
  showFilter: boolean;
  filters: {
    Query: string;
    SortType: string;
    DateFrom: string;
    DateTo: string;
    Page: number;
    PerPage: number;
    Type: string;
    CategoryId: string;
  };
  tempFilters: {
    Query: string;
    DateFrom: string;
    DateTo: string;
  };
}

class PRFilter extends React.Component<IPRFilterProps, IPRFilterState> {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      filters: this.props.filters,
      tempFilters: {
        DateFrom: this.props.filters.DateFrom,
        DateTo: this.props.filters.DateTo,
        Query: this.props.filters.Query
      }
    };
  }

  componentDidUpdate(prevProps) {
    // If the filters value has been changed by the parent, update the state.
    if (prevProps.filters !== this.props.filters) {
      this.setState({
        filters: this.props.filters,
        tempFilters: {
          DateFrom: this.props.filters.DateFrom,
          DateTo: this.props.filters.DateTo,
          Query: this.props.filters.Query
        }
      });
    }
  }

  onFilterChange = (filterName, filterValue) => {
    let filters = { ...this.state.filters };
    filters[filterName] = filterValue;
    filters.Page = 1;
    this.setState({ filters: filters });
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filters);
    }
  };

  onTempFilterChange = (filterName, filterValue) => {
    let tempFilter = { ...this.state.tempFilters };
    tempFilter[filterName] = filterValue;
    this.setState({ tempFilters: tempFilter });
  };

  onResetFilters = () => {
    let tempFilter = {
      DateFrom: "",
      DateTo: "",
      Query: ""
    };
    this.setState({ tempFilters: tempFilter });
    this.onApplyFilter(tempFilter);
  }

  onApplyFilter = tempfilter => {
    let filters = { ...this.state.filters };
    filters.Query = tempfilter.Query;
    filters.DateFrom = tempfilter.DateFrom;
    filters.DateTo = tempfilter.DateTo;
    filters.Page = 1;
    this.setState({ filters: filters });
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filters);
    }
  };

  onToggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  containsItemInArray = (array, item) => {
    return array.indexOf(item) !== -1;
  };

  getSortFilterValues = () => {
    return [
      {
        Value: "Newest",
        Name: "Newest",
        Selected: this.state.filters.SortType === "Newest"
      },
      {
        Value: "Oldest",
        Name: "Oldest",
        Selected: this.state.filters.SortType === "Oldest"
      },
      {
        Value: "AtoZ",
        Name: "AtoZ",
        Selected: this.state.filters.SortType === "AtoZ"
      },
      {
        Value: "ZtoA",
        Name: "ZtoA",
        Selected: this.state.filters.SortType === "ZtoA"
      }
    ];
  };

  handleTypeChange = event => { this.onFilterChange("Type", event.target.value) };

  render() {
    let filterClass = "pr-filter " + this.props.width;
    return (
      <div className={filterClass}>
        <div className="pr-filter-container">
          {!this.props.mediaType && (
            <h1 className="light">{this.props.label}</h1>
          )}

          {((!this.props.mediaType && this.props.showTypes) || this.props.showPaginationOptions) && (
            <div className="general-filter-container">
              {!this.props.mediaType && this.props.showTypes && (
                <div className="media-type-container">
                  {this.props.showAllOption &&
                    <FormControlLabel classes={{ root: "radio-container", label: "radio-label" }} value="All" checked={this.state.filters.Type === 'All'}
                      control={<Radio classes={{ root: "radio-button", checked: "checked" }} disableRipple />} label="All" onChange={this.handleTypeChange} />
                  }
                  <FormControlLabel classes={{ root: "radio-container", label: "radio-label" }} value="PressRelease" checked={this.state.filters.Type === 'PressRelease'}
                    control={<Radio classes={{ root: "radio-button", checked: "checked" }} disableRipple />} label="Press Releases" onChange={this.handleTypeChange} />
                  <FormControlLabel classes={{ root: "radio-container", label: "radio-label" }} value="Image" checked={this.state.filters.Type === 'Image'}
                    control={<Radio classes={{ root: "radio-button", checked: "checked" }} disableRipple />} label="Images" onChange={this.handleTypeChange} />
                  <FormControlLabel classes={{ root: "radio-container", label: "radio-label" }} value="Video" checked={this.state.filters.Type === 'Video'}
                    control={<Radio classes={{ root: "radio-button", checked: "checked" }} disableRipple />} label="Videos" onChange={this.handleTypeChange} />
                  <FormControlLabel classes={{ root: "radio-container", label: "radio-label" }} value="PressKit" checked={this.state.filters.Type === 'PressKit'}
                    control={<Radio classes={{ root: "radio-button", checked: "checked" }} disableRipple />} label="Press Kits" onChange={this.handleTypeChange} />
                </div>
              )}
              {this.props.width !== "xs" && this.props.showPaginationOptions && (
                <div className="other-type-container">
                  <div className="per-page">
                    <span>Items per page</span>
                    <PRDropdown
                      id={0}
                      theme="light"
                      filter={{
                        Values: [
                          {
                            Value: 10,
                            Name: "10",
                            Selected: this.state.filters.PerPage === 10
                          },
                          {
                            Value: 20,
                            Name: "20",
                            Selected: this.state.filters.PerPage === 20
                          },
                          {
                            Value: 30,
                            Name: "30",
                            Selected: this.state.filters.PerPage === 30
                          }
                        ]
                      }}
                      onChange={(id, name, value) => {
                        this.onFilterChange("PerPage", value);
                      }}
                    />
                  </div>
                  <div>
                    <span>Sort by</span>
                    <PRDropdown
                      id={0}
                      theme="light"
                      filter={{ Values: this.getSortFilterValues() }}
                      onChange={(id, name, value) => {
                        this.onFilterChange("SortType", value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="categories-filter-container">
            <Categories
              theme="light"
              selectedCategory={this.state.filters.CategoryId}
              onChange={data => {
                this.onFilterChange("CategoryId", data);
              }}
            />
          </div>

          {this.props.width === "xs" && this.props.showPaginationOptions && (
            <PRDropdown
              id={0}
              theme="light"
              filter={{ Values: this.getSortFilterValues() }}
              onChange={(id, name, value) => {
                this.onFilterChange("SortType", value);
              }}
              label="Sort by"
            />
          )}

        </div>
        <div className="specific-filter-container">

          <div onClick={this.onToggleFilter} className="toggle-filter">
            <div>{this.state.showFilter ? "Hide Filter" : "Show Filter"}</div>
            <PRIcon>
              {this.state.showFilter ? <UpArrowIcon /> : <DownArrowIcon />}
            </PRIcon>
          </div>
          <div
            className={
              "filter-row-container" +
              (this.state.showFilter ? " show-filter" : " hide-filter")
            }
          >
            <div className="filter-row">
              <div className="date-filter-column">
                <div className="date-filter-row">
                  <Date
                    options={{ theme: "light" }}
                    label="Date range"
                    placeholder="From"
                    onChange={date => {
                      this.onTempFilterChange("DateFrom", date);
                    }}
                    selectedDate={this.state.filters.DateFrom}
                  />
                  <Date
                    options={{ theme: "light" }}
                    placeholder="To"
                    onChange={date => {
                      this.onTempFilterChange("DateTo", date);
                    }}
                    selectedDate={this.state.filters.DateTo}
                  />
                </div>
              </div>
              <div className="query-filter-column">
                <PRTextField
                  options={{ theme: "light" }}
                  value={this.state.filters.Query}
                  label="Keyword"
                  placeholder="E.g. sedan, hybrid, ux"
                  onChange={(value, name) => {
                    this.onTempFilterChange("Query", value);
                  }}
                />
              </div>
            </div>
            <div className="filter-button-container">
              <PRButton
                data={{ type: "primary" }}
                className="submit-btn orange"
                onClick={() => {
                  this.onApplyFilter(this.state.tempFilters);
                }}
              >
                Apply Filters
                  </PRButton>
              <PRButton
                data={{ type: "primary" }}
                onClick={this.onResetFilters}
              >
                Cancel
                  </PRButton>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default withWidth()(PRFilter);
