import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import AliceCarousel from "react-alice-carousel";
import "./PRCarousel.scss";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRCarouselItem from "./PRCarouselItem/PRCarouselItem";
import { LeftArrowIcon, RightArrowIcon } from "../../PRIcon/Icons";

export interface PRCarouselProps {
  width: string;
  data: {
    CategoryLabel?: string;
    Vehicles?: Array<{
      Label: string;
      Image: string;
      Url: string;
      Description: string;
    }>;
    Items?: Array<any>;
  };
}

interface PRCarouselState {
  currentIndex: number;
  show: boolean;
}

class PRCarousel extends React.Component<PRCarouselProps, PRCarouselState> {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      show: false
    };
  }

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  responsive = {
    0: { items: 1 },
    600: { items: 3 },
    960: { items: 4 },
    1280: { items: 5 }
  };

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true });
    }, 550);
  }

  getCarouselItems = () => {
    let carouselItems = [];

    this.props.data.Vehicles.map((item, $index) => {
      carouselItems.push(
        <PRCarouselItem key={"carousel-item-" + $index} data={item} />
      );
    });

    return carouselItems;
  };

  render() {
    const { width } = this.props;
    let classname = "pr-carousel " + width;
    let items = this.props.data.Items ? this.props.data.Items : this.getCarouselItems();
    let carouselMaxWidth;
    let showArrows;
    if(this.props.width === "xs") {
      carouselMaxWidth = "216px";
      showArrows = items.length > 1;
    }
    else if(this.props.width === "sm") {
      carouselMaxWidth = items.length < 3 ? (items.length * 216) + "px" : (3 * 216) + "px"
      showArrows = items.length > 3;
    }
    else if(this.props.width === "md") {
      carouselMaxWidth = items.length < 4 ? (items.length * 216) + "px" : (4 * 216) + "px"
      showArrows = items.length > 4;
    }
    else {
      carouselMaxWidth = items.length < 5 ? (items.length * 216) + "px" : (5 * 216) + "px"
      showArrows = items.length > 5;
    }

    if ((this.state.show)) {
      return (
        <div className={classname}>
          {showArrows && <div className="pr-carousel-control-prev" onClick={this.slidePrev}>
            <PRIcon>
              <LeftArrowIcon />
            </PRIcon>
          </div>}
          <div className="pr-carousel-container" style={{maxWidth: carouselMaxWidth}}>
            <AliceCarousel
              responsive={this.responsive}
              dotsDisabled={true}
              buttonsDisabled={true}
              slideToIndex={this.state.currentIndex}
              onSlideChanged={this.onSlideChanged}
            >
              {items}
            </AliceCarousel>
          </div>
          {showArrows && <div className="pr-carousel-control-next" onClick={this.slideNext}>
            <PRIcon>
              <RightArrowIcon />
            </PRIcon>
          </div>}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withWidth()(PRCarousel);
