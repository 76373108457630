import React from "react";
import TextField from "@material-ui/core/TextField";
import "./PRTextField.scss";

export interface PRTextFieldProps {
  required?: boolean;
  label?: string;
  options?: {
    theme?: string;
  };
  error?: boolean;
  type?: string;
  pattern?: string;
  maxLength?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  onChange?: Function;
}

interface IPRTextFieldState {
  error: boolean;
  value: any
}

export default class PRTextField extends React.Component<PRTextFieldProps, IPRTextFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      error: this.props.error,
      value: this.convertUndefinedToEmptyString(this.props.value) // If value is undefined, React will complain about the input being uncontrolled.
    };
  }

  element = null;

  handleChange = event => {
    let error =
      (!event.target.value && this.props.required) ||
      !(this.element as HTMLElement).getElementsByTagName("input")[0].validity
        .valid;
    this.setState({ error: error, value: event.target.value });
    this.props.onChange(event.target.value, this.props.name);
  };

  convertUndefinedToEmptyString = (value) => {
    return (value === undefined || value === null) ? "" : value;
  }

  componentDidUpdate(prevProps) {
    // If the error value has been changed by the parent, update the state.
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    // If the value has been changed by the parent, update the state.
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.convertUndefinedToEmptyString(this.props.value) });
    }
  }

  render() {
    let textfieldClass =
      "pr-textfield" +
      (this.props.required ? " required" : "") +
      (this.props.options ? " " + this.props.options.theme : "");
    let placeholder = this.state.error ? `This field is ${!this.state.value ? "required" : "invalid"}` : this.props.placeholder;
    return (
      <div className={textfieldClass} ref={element => (this.element = element)}>
        <TextField
          required={this.props.required ? true : false}
          label={this.props.label}
          error={this.state.error}
          margin="normal"
          onChange={this.handleChange}
          type={this.props.type}
          inputProps={{ pattern: this.props.pattern, maxLength: this.props.maxLength }}
          value={this.state.value}
          name={this.props.name}
          placeholder={placeholder}
          className="textfield-root"
          InputProps={{ disableUnderline: true, classes: { root: "input-root", error: "input-error", input: "input-input" } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    );
  }
}
