import React from "react";
import Popover from "@material-ui/core/Popover";
import { PRIcon } from "../../PRIcon/PRIcon";
import Placeholder from "../../../js/extensions/Placeholder";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import "./PRMenu.scss";
import { MenuIcon, DownArrowIcon } from "../../PRIcon/Icons";
import { LexusLogo } from "../Images/Images";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface IMenuItem {
  Label: string;
  Url?: string;
  SecondaryMenu?: Array<IMenuItem>;
}

export interface PRMenuProps {
  width: Breakpoint;
  data?: {
    VehicleCategories: Array<{
      CategoryLabel: string;
      Vehicles: Array<{
        Label: string;
        Image: string;
        Url: string;
      }>;
    }>;
    PrimaryMenu: Array<IMenuItem>;
    VehicleSubMenu?: Array<IMenuItem>;
    DownloadCartLink?: string;
  };
  placeholder?: boolean;
}

class PRMenu extends React.Component<PRMenuProps, {}> {
  state = {
    anchorEl: null
  };

  static propTypes = {
    width: PropTypes.string.isRequired
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    event.currentTarget.classList.add("active");
  };

  handleClose = () => {
    this.state.anchorEl.classList.remove("active");
    this.setState({ anchorEl: null });
  };

  isCurrentUrl = (item) => {
    let currentUrl = window.location.href;
    let isMatch = false;
    if(item.Url && currentUrl.indexOf(item.Url) >= 0) {
      isMatch = true;
    }
    item.SecondaryMenu.forEach((subItem) => {
      if(subItem.Url && currentUrl.indexOf(subItem.Url) >= 0) {
        isMatch = true;
      }
    });
    return isMatch;
  };

  renderExpansionPanel = (key: string, item: IMenuItem) => {
    if (item.SecondaryMenu && item.SecondaryMenu.length) {
      return (
        <ExpansionPanel key={key} square classes={{ root: 'expansion-panel', expanded: 'expanded' }}>
          <ExpansionPanelSummary
            classes={{
              root: 'expansion-panel-summary',
              expanded: 'expanded',
              content: 'expansion-panel-summary-content'
            }}
          >
            <div className="side-menu-item-container">
              <div className="side-menu-item">
                {item.Url ?
                  <a href={item.Url}>{item.Label}</a> : item.Label
                }
              </div>
              <div className="arrow-button">
                <PRIcon>
                  <DownArrowIcon />
                </PRIcon>
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: 'expansion-panel-details' }}>
            <div className="side-menu-subitems">
              {item.SecondaryMenu.map((subitem, $index) => {
                return this.renderExpansionPanel(`menu-subitem-${$index}`, subitem);
              })}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    else {
      return (
        <div className="side-menu-item-container">
          <div className="side-menu-item no-subitems">
            {item.Url ?
              <a href={item.Url}>{item.Label}</a> : item.Label
            }
          </div>
        </div>
      );
    }
  };

  render() {
    const { anchorEl } = this.state;

    var popovers = [];

    var menuItems = [];

    var sideMenuItems = [];

    this.props.data.PrimaryMenu.forEach((element, $index) => {
      if (this.props.width !== "xs" && this.props.width !== "sm") {
        let isCurrent = this.isCurrentUrl(element);
        let label = "menu-item-" + $index;
        let className = "pr-menu-item " + label + (isCurrent ? " current" : "");
        let menuItem = element.Url ?
          (
            <div
              key={"pr-menu-item-" + $index}
              className={className}
              tabIndex={0}
            >
              <a href={element.Url}>{element.Label}</a>
            </div>
          ) :
          (
            <div
              key={"pr-menu-item-" + $index}
              className={className}
              aria-owns={anchorEl ? label : null}
              aria-haspopup="true"
              onClick={this.handleClick}
              tabIndex={0}
            >
              <span>{element.Label}</span>
            </div>
          );

        menuItems.push(menuItem);

        var secondaryItems = [];
        element.SecondaryMenu.forEach((secondaryItem, $index2) => {
          secondaryItems.push(
            <li key={"sub-index-" + $index + $index2}>
              <a href={secondaryItem.Url}>{secondaryItem.Label}</a>
            </li>
          );
        });
        var popover = (
          <Popover
            className="menu-item-popover"
            classes={{ paper: 'popover-paper' }}
            key={"menu-item-popover" + $index}
            open={anchorEl != null && anchorEl.classList.contains(label)}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <ul className="popover-menu-list" style={{ width: anchorEl != null ? anchorEl.offsetWidth : "auto" }}>
              {secondaryItems}
            </ul>
          </Popover>
        );
        popovers.push(popover);
      } else {
        sideMenuItems.push(this.renderExpansionPanel(`menu-item-${$index}`, element));
      }
    });

    if (this.props.width === "sm" || this.props.width === "xs") {
      let vehicleObject: IMenuItem = { Label: 'Vehicles' };
      vehicleObject.SecondaryMenu = this.props.data.VehicleCategories.map(category => {
        return {
          Label: category.CategoryLabel,
          SecondaryMenu: category.Vehicles.map(vehicle => {
            return { Label: vehicle.Label, Url: vehicle.Url };
          })
        };
      });
      return (
        <div className="pr-menu xs">
          <div className="pr-menu-top-container">
            <div className="pr-menu-container row">
              <div className="pr-menu-top-container-content">
                <div className="logo-container">
                  <a href="/">
                    <LexusLogo />
                  </a>
                </div>
                <div className="other-item-container row">
                  <Placeholder
                    placeholderKey={"LexusMenuActionContainer"}
                    isDynamic={true}
                    placeholder={this.props.placeholder}
                  >
                    {this.props.children}
                  </Placeholder>
                  <div className="menu-toggle"
                    aria-owns={this.state.anchorEl ? "mobile-menu-container" : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}>
                    <PRIcon>
                      <MenuIcon />
                    </PRIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Popover
            id="mobile-menu-container"
            className="mobile-menu-container"
            open={anchorEl != null && anchorEl.classList.contains("menu-toggle")}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            classes={{ paper: "popover-paper" }}
            marginThreshold={0}
          >
            <div className="side-menu-container" tabIndex={0}>

              {this.renderExpansionPanel(`menu-item-vehicles`, vehicleObject)}
              {sideMenuItems}
            </div>
          </Popover>


        </div>
      );
    } else {
      return (
        <div className="pr-menu">
          <div className="pr-menu-top-container">
            <div className="pr-menu-container row">
              <div className="pr-menu-top-container-content">
                <div className="logo-container">
                  <a href="/">
                    <LexusLogo />
                  </a>
                </div>
                <div className="other-item-container row">
                  <Placeholder
                    placeholderKey={"LexusMenuActionContainer"}
                    isDynamic={true}
                    placeholder={this.props.placeholder}
                  >
                    {this.props.children}
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>

          <div className="pr-menu-container-outer">
            <div className="pr-menu-container row">
              <div>
                <div
                  className="pr-menu-item vehicle"
                  aria-owns={anchorEl ? "vehicle-menu" : null}
                  aria-haspopup="true"
                  aria-label="Vehicles"
                  onClick={this.handleClick}
                  tabIndex={0}
                >
                  <span>Vehicles</span>
                </div>
                {menuItems}
              </div>
            </div>
          </div>

          <Popover
            id="vehicle-menu"
            classes={{ paper: 'popover-paper' }}
            open={anchorEl != null && anchorEl.classList.contains("vehicle")}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className="vehicle-menu-outer-container">
              <div className="vehicle-menu-container row">
                <div>
                  {this.props.data &&
                    this.props.data.VehicleCategories &&
                    this.props.data.VehicleCategories.map(
                      (vehicleCategory, $index) => {
                        return (
                          <div
                            key={"vehicle-sub-category" + $index}
                            className="vehicle-sub-category"
                          >
                            <div className="vehicle-sub-category-header">
                              {vehicleCategory.CategoryLabel}
                            </div>
                            <div className="vehicle-sub-category-content">
                              {vehicleCategory.Vehicles.map(
                                (vehicle, $index) => {
                                  return (
                                    <a
                                      key={
                                        "vehicle-sub-category-content-item-" +
                                        $index
                                      }
                                      href={vehicle.Url}
                                    >
                                      <div className="vehicle-sub-category-content-item">
                                        <img src={vehicle.Image} />
                                        <div className="vehicle-label">{vehicle.Label}</div>
                                      </div>
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="vehicle-menu-footer-container">
              <div className="vehicle-menu-footer row">
                <div>
                  {this.props.data &&
                    this.props.data.VehicleSubMenu &&
                    this.props.data.VehicleSubMenu.map((subLink, $index) => {
                      return (
                        <div
                          key={"vehicle-menu-footer-link" + $index}
                          className="vehicle-menu-footer-link"
                        >
                          <a href={subLink.Url}>{subLink.Label}</a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Popover>
          {popovers}
        </div>
      );
    }
  }
}

export default withWidth()(PRMenu);
