import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import "./scaffolding.scss";
import withWidth from "@material-ui/core/withWidth";

export interface PRSectionProps {
    data?: {
        BackgroundColored?: boolean;
        SectionHeader?: string;
        IsShownInMobile?: boolean;
    }
    placeholder?: boolean;
    width: string;
    isEditing?: boolean;

}

class PRSection extends React.Component<PRSectionProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        let colored = this.props.data.BackgroundColored ? ' colored' : ''
        let className = "PR-section" + colored;

        let header = null;
        if (this.props.data.SectionHeader) {
            header = <h2 className="section-header center" dangerouslySetInnerHTML={{ __html: this.props.data.SectionHeader }}></h2>
        }

        if (this.props.isEditing || (!this.props.isEditing)) {
            return (
                <div className={className}>
                    <div className="PR-section-container">
                        {header}
                        <Placeholder placeholderKey={'LexusSection'} isDynamic={true} placeholder={this.props.placeholder}>
                            {this.props.children}
                        </Placeholder>
                    </div>
                </div>
            );
        }

        return (<div></div>);
    }
}

export default withWidth()(PRSection);