import * as React from "react";
import "./Notification.scss";
import { Snackbar } from "@material-ui/core";

interface INotificationProps {
    open: boolean;
    message: string;
    onClose: Function;
}

interface INotificationState {
    open: boolean;
}

export class Notification extends React.Component<INotificationProps, INotificationState> {
    constructor(props) {
        super(props);

        this.state = { open: this.props.open };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }
    }

    render() {
        return (
            <Snackbar
                onClick={this.onClick}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={3000}
                onClose={this.onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    role: "alert", classes: { root: "notification", message: "notification-message" }
                }}
                message={<span id="message-id">{this.props.message}</span>}
            />
        );
    }

    onClose = () => {
        this.props.onClose();
    };

    onClick = event => {
        event.stopPropagation();
        this.props.onClose();
    };
}
