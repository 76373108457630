import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import "./scaffolding.scss";
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

export interface PR2Column6040LayoutProps {
    placeholder?: boolean;
    data: {
        HideRightColumnOnMobile?: boolean; 
    }
    width: string;
}

class PR2Column6040Layout extends React.Component<PR2Column6040LayoutProps, {}> {

    static propTypes = {
        width: PropTypes.string.isRequired
    };

    render() {
        return (
            <div className="PRSplitLayout">
                <div className="layout-container">
                    <div className="row">
                        <div className="leftGutter">
                            <Placeholder placeholderKey={'LexusLeft'} isDynamic={true} placeholder={this.props.placeholder}>
                            {this.props.children ? this.props.children[0] : null}
                            </Placeholder>
                        </div>
                        {
                            <div className={"rightGutter" + (this.props.data.HideRightColumnOnMobile ? ' hide-on-mobile' : '')}>
                                <Placeholder placeholderKey={'LexusRight'} isDynamic={true} placeholder={this.props.placeholder}>
                                {this.props.children ? this.props.children[1] : null}
                                </Placeholder>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withWidth()(PR2Column6040Layout);