import React from "react";
import "./PRCheckbox.scss";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface PRCheckboxProps {
  theme?: string;
  label?: string;
  checked?: boolean;
  onChange?: Function;
  index?: number;
  required?: boolean;
  error?: boolean;
  name?: string;
}

interface PRCheckboxState {
  checked: boolean;
  error: boolean;
}

export default class PRCheckbox extends React.Component<PRCheckboxProps, PRCheckboxState> {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked || false,
      error: this.props.error
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  onClick = () => {
    let value = !this.state.checked;
    this.setState({ checked: value, error: !value && this.props.required });
    this.props.onChange(value, this.props.index);
  };

  handleChange = event => {
    this.setState({ checked: event.target.checked });
    this.props.onChange(event.target.checked, this.props.index);
  };

  render() {
    let checkboxClass = `pr-checkbox ${(this.props.required ? "required" : "")} ${(this.state.error ? "error" : "")} ${(this.props.theme || "")}`;
    return (
      <div className={checkboxClass}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checked}
              onChange={this.handleChange}
              inputProps={{ required: this.props.required, name: this.props.name }}
              disableRipple={true}
              classes={{ root: "checkbox", checked: "checked" }}
            />
          }
          label={this.props.children ? this.props.children : this.props.label}
          classes={{ label: "label" }}
        />
      </div>
    );
  }
}
