import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { PRIcon } from "../../../../PRIcon/PRIcon";
import "./PRDropdown.scss";
import { DownArrowIcon } from "../../../../PRIcon/Icons";
import { InputLabel, Input } from "@material-ui/core";

export interface PRDropdownProps {
  required?: boolean;
  id: any;
  onChange: Function;
  filter: {
    Values?: Array<{
      Value: any;
      Name: string;
      Selected: boolean;
    }>;
  };
  theme?: string;
  error?: boolean;
  label?: string;
}

interface IPRTextFieldState {
  error: boolean;
  selected: string;
}

class PRDropdown extends React.Component<PRDropdownProps, IPRTextFieldState> {
  constructor(props) {
    super(props);

    this.state = {
      error: this.props.error,
      selected: this.getSelectedValue(this.props.filter.Values)
    };
  }

  handleFilterChange = event => {
    let error = (!event.target.value && this.props.required);
    this.setState({ error: error, selected: event.target.value });
    this.props.onChange(this.props.id, event.target.name, event.target.value);
  };

  componentDidUpdate(prevProps) {
    // If the selected value has been changed by the parent, update the state.
    let prevSelectedValue = this.getSelectedValue(prevProps.filter.Values);
    let newSelectedValue = this.getSelectedValue(this.props.filter.Values);
    if (prevSelectedValue !== newSelectedValue) {
      this.setState({ selected: newSelectedValue });
    }
    // If the error value has been changed by the parent, update the state.
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  getSelectedValue = (values) => {
    let selectedValues = values.filter(value => value.Selected);
    return selectedValues.length ? selectedValues[0].Value : '';
  }

  render() {
    let className = "pr-dropdown " + (this.props.required ? " required " : "") + this.props.theme;
    let placeholder = this.state.error ? `This field is ${!this.state.selected ? "required" : "invalid"}` : "Select";
    return (
      <div className={className}>
        <FormControl margin="normal"
          classes={{ root: "form-control" }}
          error={this.state.error}
        >
          {this.props.label ?
            <InputLabel
              FormLabelClasses={{ root: "dropdown-label", focused: "focused" }}
              shrink={true}
              htmlFor="dropdown">
              {this.props.label}
            </InputLabel> : ""}
          <Select
            error={this.state.error}
            value={this.state.selected}
            onChange={this.handleFilterChange}
            classes={{ root: "dropdown-root", select: "dropdown-select", icon: "dropdown-icon" }}
            input={
              <Input
                classes={{ root: "input-root", error: "input-error" }}
                name={"filter-" + this.props.id}
                id="dropdown"
                disableUnderline={true} 
                error={this.state.error}/>
            }
            displayEmpty
            IconComponent={props => (<PRIcon {...props} className={"material-icons " + props.className}><DownArrowIcon /></PRIcon>)}
            MenuProps={{ classes: { paper: "dropdown-menu-paper" }, MenuListProps: { disablePadding: true } }}
          >
            <MenuItem value="" classes={{ root: "menu-item", selected: "selected" }}>
              {placeholder}
            </MenuItem>
            {this.props.filter.Values.map((item, $index) => {
              return (
                <MenuItem key={$index} value={item.Value} classes={{ root: "menu-item", selected: "selected" }}>
                  {item.Name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default PRDropdown;
