import React from 'react';
import Reorder, { reorder } from 'react-reorder';
import PRTextField from '../PageContent/Form/Fields/PRTextField/PRTextField';
import { FileSelection } from './FileSelection/FileSelection';
import Constants from '../constants';
import PRButton from '../PageContent/PRButton/PRButton';
import './AdminPressRelease.scss';
import { PressRoomApi } from '../../services/pressRoomApi';
import FileDrop from '../PageContent/Form/Fields/FileDrop/FileDrop';
import { Categories } from '../Categories/Categories';
import PRCheckbox from '../PageContent/Form/Fields/PRCheckbox/PRCheckbox';
import { IPressRelease } from '../../interfaces/press-release';
import { IPressKit } from '../../interfaces/press-kit';
import { DateTime } from "../PageContent/Date/DateTime";
import moment from 'moment';
import utils from '../../services/utils';

interface IAdminPressKitState {
    pressKit: IPressKit;
    changesSaved: boolean;
    loading: boolean;
    invalid: boolean;
    saving: boolean;
}

export default class AdminPressKit extends React.Component<any, IAdminPressKitState> {
    private formEl: HTMLFormElement = null;
    private timestamp: Date = null;

    constructor(props: any) {
        super(props)

        this.state = {
            pressKit: {
                Id: null,
                Title: '',
                ShortDescription: '',
                HomePageBanner: false,
                ThumbnailImage: null,
                CategoryId: null,
                PressReleases: [],
                Banner: null
            },
            changesSaved: true,
            loading: true,
            invalid: false,
            saving: false
        };
    }

    componentDidMount() {
        this.getPressKit();
    }

    getPressKit = async () => {
        var kvp = document.location.search.substr(1).split('&');
        var values = kvp[0].split('=');
        if (values.length > 1) {
            this.setState({ loading: true });
            let api = new PressRoomApi();
            let result = await api.getPressKit(values[1]);
            if (result.success) {
                this.setState({ pressKit: result.data, loading: false });
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    isValid = () => {
        let valid = !utils.guidIsEmpty(this.state.pressKit.CategoryId) &&
            this.state.pressKit.ThumbnailImage &&
            this.state.pressKit.Banner &&
            this.state.pressKit.PressReleases && this.state.pressKit.PressReleases.length &&
            this.formEl.checkValidity();
        this.setState({ invalid: !valid });
        return valid;
    }

    onSavePressKit = async () => {
        if (this.state.saving) {
            return;
        }

        // Publish date time must be in the future
        if (this.state.pressKit.PublishAt && moment().isAfter(this.state.pressKit.PublishAt)) {
            return;
        }

        // This field has to be validated on save otherwise it will fail the validation in the backend.
        // All other fields are only validated on publish still.
        if (this.state.pressKit.ShortDescription && this.state.pressKit.ShortDescription.length > 85) {
            return;
        }

        this.setState({ saving: true });
        let api = new PressRoomApi();
        let result = await api.savePressKit(this.state.pressKit);
        if (result.success) {
            let pr = { ...this.state.pressKit };
            pr.Id = result.data;
            pr.Published = false;
            this.timestamp = new Date();
            this.setState({ changesSaved: true, pressKit: pr, saving: false });
            return pr.Id;
        }
    }

    onPublishPressKit = async () => {
        if (this.state.saving) {
            return;
        }
        if (this.state.changesSaved && this.state.pressKit.Id) {
            if (this.isValid()) {
                this.setState({ saving: true });
                let api = new PressRoomApi();
                await api.publishPressKit(this.state.pressKit.Id);
                this.goToDashboard();
            }
        }
    }

    onDataChange = (data, name) => {
        let pressKit = { ...this.state.pressKit };
        pressKit[name] = data;
        this.setState({ pressKit: pressKit, changesSaved: false });
    }

    cancel = () => {
        this.goToDashboard();
    }

    goToDashboard = () => {
        window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            Constants.Links.PressKitDashboard;
    }

    render() {
        let saveDisabled = this.state.changesSaved;
        let publishDisabled = !this.state.changesSaved || !this.state.pressKit.Id || this.state.pressKit.Published || !!this.state.pressKit.PublishAt;

        return (
            <div className="admin-panel-container">
                <div>
                    <div className="panel-header">
                        <h1>Press kit</h1>
                        <div className="publish-buttons">
                            <PRButton
                                data={{ type: 'primary' }}
                                className={saveDisabled && "disabled"}
                                onClick={!saveDisabled && this.onSavePressKit}>
                                Save Draft
                            </PRButton>
                            <PRButton
                                data={{ type: 'primary' }}
                                className={publishDisabled && "disabled"}
                                onClick={!publishDisabled && this.onPublishPressKit}>
                                Publish
                            </PRButton>
                            <PRButton
                                data={{ type: 'secondary' }}
                                onClick={this.cancel}>
                                {this.state.changesSaved ? 'Back' : 'Cancel'}
                            </PRButton>
                            <div className="publish-status">
                                {this.timestamp && <div>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</div>}
                                {this.state.changesSaved && this.state.pressKit.Id && !this.state.pressKit.Published && !!this.state.pressKit.PublishAt &&
                                    <div>Will be published at {moment(this.state.pressKit.PublishAt).format(Constants.TimeStampFormat)}</div>}
                            </div>
                        </div>
                    </div>

                    {
                        this.state.saving &&
                        <div className="loading"></div>
                    }
                    {!this.state.loading && <div className="admin-panel-inner-container">
                        <form ref={form => (this.formEl = form)} noValidate>
                            <DateTime
                                label="Publish at"
                                onChange={data => {
                                    this.onDataChange(data, "PublishAt");
                                }}
                                selectedDateTime={this.state.pressKit.PublishAt}
                                clearable={true}
                                disablePast={true}
                            />
                            <PRTextField
                                required
                                label="Title"
                                value={this.state.pressKit.Title}
                                onChange={(data) => { this.onDataChange(data, 'Title') }}
                                error={this.state.invalid && !this.state.pressKit.Title}>
                            </PRTextField>
                            <PRTextField
                                maxLength="85"
                                label="Short description"
                                value={this.state.pressKit.ShortDescription}
                                onChange={(data) => { this.onDataChange(data, 'ShortDescription') }}
                                error={(this.state.invalid && !this.state.pressKit.ShortDescription) || (this.state.pressKit.ShortDescription && this.state.pressKit.ShortDescription.length > 85)}>
                            </PRTextField>
                            <PRCheckbox
                                label="Display on Homepage carousel"
                                checked={this.state.pressKit.HomePageBanner}
                                onChange={(value: boolean) => { this.onDataChange(value, 'HomePageBanner'); }} />
                            <Categories
                                required
                                onChange={(data) => { this.onDataChange(data, 'CategoryId') }}
                                selectedCategory={this.state.pressKit.CategoryId}
                                error={this.state.invalid && utils.guidIsEmpty(this.state.pressKit.CategoryId)}>
                            </Categories>
                            <div className="file-selection required">
                                <label>Press Releases</label>
                                {this.state.invalid &&
                                    (!this.state.pressKit.PressReleases || !this.state.pressKit.PressReleases.length) &&
                                    <div className="error-message">This field is required</div>
                                }
                                <FileSelection
                                    fileType="PressRelease"
                                    label="Press Release"
                                    multipleSelection={true}
                                    selectedItems={this.state.pressKit.PressReleases}
                                    onChange={(files) => { this.onDataChange(files, 'PressReleases') }}
                                    restrictedToId={this.state.pressKit.Id}>
                                    <PRButton data={{ type: 'primary' }} className="">Select press releases</PRButton>
                                </FileSelection>

                                <div className="create-press-release">
                                    <PRButton data={{ type: 'primary' }} onClick={this.createPressRelease}>Create press release</PRButton>
                                </div>

                                {
                                    this.state.pressKit.PressReleases && this.state.pressKit.PressReleases.length > 0 &&
                                    <div className="preview-container sortable">
                                        <Reorder reorderId="press-release-list"
                                            onReorder={this.onReorderPressReleases} >
                                            {this.state.pressKit.PressReleases.map((pressRelease: IPressRelease, $index: number) => {
                                                return (
                                                    <div className="press-release-preview" key={$index}>
                                                        <div className="press-release-order">
                                                            <h3>{$index + 1}</h3>
                                                        </div>
                                                        <div className="press-release-image"
                                                            style={{ backgroundImage: this.getPressReleaseBackgroundImage(pressRelease) }}>
                                                        </div>
                                                        <div className="press-release-content">
                                                            <h3 className="press-release-title">
                                                                {pressRelease.Title}
                                                            </h3>
                                                            <p className="press-release-description">
                                                                {pressRelease.ShortDescription}
                                                            </p>
                                                        </div>
                                                        <div className="press-release-actions">
                                                            <PRButton data={{ type: "tertiary" }} onClick={() => this.editPressRelease(pressRelease)}>Edit</PRButton>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Reorder>
                                    </div>
                                }

                            </div>

                            <div className="banner">
                                <FileDrop
                                    required
                                    type="image"
                                    label={"Banner Image: " + Constants.ImageDimensions.Banner.width + "x" + Constants.ImageDimensions.Banner.height}
                                    value={this.state.pressKit && this.state.pressKit.Banner ? this.state.pressKit.Banner.ImageUrl : null}
                                    onChange={(data) => { this.onDataChange(data, 'Banner') }}
                                    error={this.state.invalid && !this.state.pressKit.Banner}>
                                </FileDrop>
                            </div>

                            <div className="thumbnail">
                                <FileDrop
                                    required
                                    type="image"
                                    label={"Thumbnail Image: " + Constants.ImageDimensions.Thumbnail.width + "x" + Constants.ImageDimensions.Thumbnail.height}
                                    value={this.state.pressKit && this.state.pressKit.ThumbnailImage ? this.state.pressKit.ThumbnailImage.ImageUrl : null}
                                    onChange={(data) => { this.onDataChange(data, 'ThumbnailImage') }}
                                    error={this.state.invalid && !this.state.pressKit.ThumbnailImage}>
                                </FileDrop>
                            </div>
                        </form>
                    </div>}

                    <div className="panel-footer">
                        <div className="publish-buttons">
                            <PRButton
                                data={{ type: 'primary' }}
                                className={saveDisabled && "disabled"}
                                onClick={!saveDisabled && this.onSavePressKit}>
                                Save Draft
                            </PRButton>
                            <PRButton
                                data={{ type: 'primary' }}
                                className={publishDisabled && "disabled"}
                                onClick={!publishDisabled && this.onPublishPressKit}>
                                Publish
                            </PRButton>
                            <PRButton
                                data={{ type: 'secondary' }}
                                onClick={this.cancel}>
                                {this.state.changesSaved ? 'Back' : 'Cancel'}
                            </PRButton>
                            <div className="publish-status">
                                {this.timestamp && <div>Saved {moment(this.timestamp).format(Constants.TimeStampFormat)}</div>}
                                {this.state.changesSaved && this.state.pressKit.Id && !this.state.pressKit.Published && !!this.state.pressKit.PublishAt &&
                                    <div>Will be published at {moment(this.state.pressKit.PublishAt).format(Constants.TimeStampFormat)}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getPressReleaseBackgroundImage = (pressRelease: IPressRelease) => {
        return "url('" + (pressRelease.Url || (pressRelease.ThumbnailImage && pressRelease.ThumbnailImage.ImageUrl) || utils.getEmptyImage()) + "')";
    };

    onReorderPressReleases = (event, previousIndex, nextIndex) => {
        let pressReleases = reorder(this.state.pressKit.PressReleases, previousIndex, nextIndex);
        this.onDataChange(pressReleases, 'PressReleases');
    };

    createPressRelease = async () => {
        // save press kit draft
        let pressKitId = await this.onSavePressKit();

        // go to press release edit page
        window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            Constants.Links.PressReleaseForm +
            "?pressKitId=" +
            pressKitId;
    };

    editPressRelease = async (pressRelease: IPressRelease) => {
        // save press kit draft
        let pressKitId = await this.onSavePressKit();

        // go to press release edit page
        window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            Constants.Links.PressReleaseForm +
            "?id=" +
            pressRelease.Id +
            "&pressKitId=" +
            pressKitId;
    };
}