import React from "react";
import "./PRTabs.scss";

export interface IPRTabsProps {
  data?: any;
}

export interface IPRTabsState {
  value: number;
}

export default class PRTabs extends React.Component<
  IPRTabsProps,
  IPRTabsState
> {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  handleChange = value => {
    this.setState({ value });
  };

  render() {
    var tabList = [];

    var tabs = [];
    this.props.data.TabItems.map((item, $index) => {
      let className =
        "pr-tab-label-container" +
        (this.state.value === $index ? " active" : "");
      tabList.push(
        <div
          key={"tab-label-container-" + $index}
          tabIndex={0}
          className={className}
          onClick={() => {
            this.handleChange($index);
          }}
        >
          <div className="pr-tab-label">{item.Label}</div>
        </div>
      );
      let tabsClass = this.state.value === $index ? "active" : "";
      tabs.push(
        <div
          key={"tab-label-sub-container-" + $index}
          className={tabsClass}
          dangerouslySetInnerHTML={{ __html: this.props.data.Children[$index] }}
        />
      );
    });

    return (
      <div className={`pr-tabs ${this.props.data.Layout || ""}`}>
        <div className="pr-tabs-header-container">
          <div className="pr-tabs-header-list">{tabList}</div>
        </div>
        <div className="pr-tabs-content">{tabs}</div>
      </div>
    );
  }
}
