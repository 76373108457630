import React from "react";
import PRDropdown from "../PageContent/Form/Fields/PRDropdown/PRDropdown";
import "./Categories.scss";
import { PressRoomApi } from "../../services/pressRoomApi";
import utils from "../../services/utils";

interface ICategoriesProps {
  required?: boolean;
  onChange?: Function;
  root?: string;
  selectedCategory?: string;
  categories?: Array<any>;
  error?: boolean;
  label?: string;
  theme?: string;
  name?: string;
}

interface ICategoriesState {
  categories: Array<any>;
  error: boolean;
}

export class Categories extends React.Component<ICategoriesProps, ICategoriesState> {
  constructor(props: ICategoriesProps) {
    super(props);
    this.state = {
      categories: this.props.categories,
      error: this.props.error
    };
  }

  public static defaultProps: Partial<ICategoriesProps> = {
    label: "Category"
  };

  async getCategories(root?: string) {
    let api = new PressRoomApi();
    let result = await api.getCategories(root);
    if (result.success) {
      let list: Array<any> = result.data.map(item => {
        return {
          Value: item.Id,
          Name: item.CategoryName,
          Selected: false
        };
      });
      this.setState({ categories: list });
    }
  }

  async getCategoriesBySelectedItem(selected: string) {
    let api = new PressRoomApi();
    let result = await api.getCategoriesForSelected(selected);
    if (result.success) {
      this.setState({ categories: result.data });
    }
  }

  getData = () => {
    if (!utils.guidIsEmpty(this.props.selectedCategory) && !this.props.root) {
      // Get the whole tree
      this.getCategoriesBySelectedItem(this.props.selectedCategory);
    }
    else {
      // Just get the items for this level
      this.getCategories(this.props.root);
    }
  }

  async componentDidMount() {
    if (!this.state.categories) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    // If the error value has been changed by the parent, update the state.
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    // If the selectedCategory value has been changed by the parent, update the state.
    if (prevProps.selectedCategory !== this.props.selectedCategory) {
      this.getData();
    }
  }

  render() {
    if (this.state.categories && this.state.categories.length) {
      return [<div className="categories" key={this.props.root + "-categories"}>
        <PRDropdown
          theme={this.props.theme}
          id="root"
          filter={{ Values: this.state.categories }}
          onChange={this.onCategorySelect}
          required={this.props.required}
          error={this.state.error}
          label={this.props.label}
        />
      </div>,
      this.state.categories.map((category, $index) => {
        return category.Selected &&
          <Categories key={category.Value + "-subcategory"}
            root={category.Value}
            onChange={this.props.onChange}
            categories={category.Filters}
            label="Sub-Category"
            theme={this.props.theme}
            name={this.props.name}
          />
      })]
    }
    return null;
  }

  onCategorySelect = (_id: string, _name: string, value: string) => {
    let newList = this.state.categories.map((category, $index) => {
      category.Selected = category.Value === value;
      return category;
    });
    this.setState({ categories: newList });
    if (typeof this.props.onChange !== 'undefined') {
      this.props.onChange(value || this.props.root, this.props.name);
    }
  }

}
