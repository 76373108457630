import React from "react";
import Placeholder from "../../../js/extensions/Placeholder";
import withWidth from "@material-ui/core/withWidth";
import PRBanner from "../..//PageContent/PRBanner/PRBanner";
import "./PRPressKit.scss";

export interface PressKitProps {
  data: {
    Banner?: {
      BannerImage?: string;
      HeaderText: string;
      SubHeader?: string;
    };
    Header: string;
    PressReleases: Array<{
      Id: string;
      Label: string;
    }>;
    isSitecore?: boolean;
    Children?: Array<string>;
  };
  width: string;
  placeholder?: boolean;
}

class PressKit extends React.Component<PressKitProps, {}> {
  currentPrCode: string;

  constructor(props) {
    super(props);

    this.currentPrCode = this.getQueryStringValue("pr-code") || (this.props.data.PressReleases.length && this.props.data.PressReleases[0].Id);
  }

  node = null;

  handleTabChange = item => {
    this.insertParam("pr-code", item.Id);
  };

  componentDidMount() {
    if (this.node) {
      var arr = this.node.getElementsByTagName("script");
      for (var n = 0; n < arr.length; n++) eval(arr[n].innerHTML);
    }
  }

  getQueryStringValue = (key: string) => {
    var kvp = document.location.search.substr(1).split("&");
    var i = kvp.length;
    var x: Array<string>;
    while (i--) {
      x = kvp[i].split("=");

      if (x[0] == key) {
        return x[1];
      }
    }
    return null;
  };

  insertParam = (key, value) => {
    key = encodeURI(key);
    value = encodeURI(value);

    var kvp = document.location.search.substr(1).split("&");

    var i = kvp.length;
    var x;
    while (i--) {
      x = kvp[i].split("=");

      if (x[0] == key) {
        x[1] = value;
        kvp[i] = x.join("=");
        break;
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, value].join("=");
    }

    //this will reload the page, it's likely better to store this until finished
    document.location.search = kvp.join("&");
  };

  render() {
    let tabList = [];

    this.props.data.PressReleases.map((child, $index) => {
      let className = `complex-tab ${(this.currentPrCode === child.Id ? "active" : "")}`;
      tabList.push(
        <div
          key={$index}
          onClick={() => {
            this.handleTabChange(child);
          }}
          className={className}
        >
          <span className="tab-label">{child.Label ? child.Label : $index + 1}</span>
        </div>
      );
    });

    let className = "press-kit includes-flag complex-tabs";

    return (
      <div>
        {this.props.data.Banner && <PRBanner data={this.props.data.Banner} />}

        <div className={className}>
          <div className="header-container">
            <div className="header-inner-container">
              <div className="header-flag">Press Kit</div>
              <div className="title-filter">
                <h1 className="light">{this.props.data.Header}</h1>
              </div>
              <div className="complex-tab-list">{tabList}</div>
            </div>
          </div>
          <div className="pr-tabs-content" ref={node => (this.node = node)}>
            <div className="active">
              <Placeholder
                placeholderKey={"LexusPressKitContainer"}
                isDynamic={true}
                placeholder={this.props.placeholder}
              >
                {this.props.children}
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(PressKit);
