import React from "react";
import { PRIcon } from "../../../PRIcon/PRIcon";
import { PenIcon } from "../../../PRIcon/Icons";
import { MediaForm } from "../../MediaForm/MediaForm";
import Constants from "../../../constants";

interface IEditItemProps {
  itemType: string;
  itemId: string;
  onEdit?: Function;
}

interface IEditItemState {
  formOpen: boolean;
}

export class EditItem extends React.Component<IEditItemProps, IEditItemState> {
  constructor(props: IEditItemProps) {
    super(props);

    this.state = {
      formOpen: false
    };
  }

  editItem = async (e) => {
    e.stopPropagation();
    if (this.props.itemType === "Image" || this.props.itemType === "Video") {
      this.setState({ formOpen: true });
    } else if (this.props.itemType === "Press Release") {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        Constants.Links.PressReleaseForm +
        "?id=" +
        this.props.itemId;
    } else if (this.props.itemType === "Press Kit") {
      window.location.href =
        window.location.protocol +
        "//" +
        window.location.host +
        Constants.Links.PressKitForm +
        "?id=" +
        this.props.itemId;
    }
  }

  handleClose = () => {
    this.setState({ formOpen: false });
  };

  handleSave = () => {
    this.setState({ formOpen: false });
    if (this.props.onEdit) {
      this.props.onEdit();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="card-action-icon" onClick={this.editItem}>
          <PRIcon className="edit-icon">
            <PenIcon />
          </PRIcon>
        </div>
        {this.state.formOpen && (<MediaForm
          type={this.props.itemType}
          editMode={true}
          itemId={this.props.itemId}
          onClose={this.handleClose}
          onSave={this.handleSave}
        />
        )}
      </React.Fragment>
    );
  }
}
