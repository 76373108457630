import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import PRAddToCart from "../../PageContent/icons/PRAddToCart";
import "./PRDocumentCard.scss";
import { DocumentIcon, DownloadIcon } from "../../PRIcon/Icons";

export interface documentCardProps {
  data: {
    Title: string;
    PressReleaseId?: string;
    PdfLink?: string;
    Type?: string;
  };
}

class PRDocumentCard extends React.Component<documentCardProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="pr-document-card">
        <div className="pr-document-icon">
          <PRIcon>
            <DocumentIcon />
          </PRIcon>
        </div>
        <div className="pr-document-content">
          {this.props.data.Title}
        </div>
        <div className="document-card-actions">
          <div className="document-card-action-item">
            <PRAddToCart
              cartItem={{ title: this.props.data.Title, type: this.props.data.Type, id: this.props.data.PressReleaseId }}
            />
          </div>
          <div className="document-card-action-item">
            <a href={this.props.data.PdfLink}>
              <PRIcon className="download">
                <DownloadIcon />
              </PRIcon>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PRDocumentCard;
