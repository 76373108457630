import * as React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import { DownloadIcon } from "../../PRIcon/Icons";

export interface DownloadItemProps {
  itemId?: any;
  className?: string;
}

class DownloadItem extends React.Component<DownloadItemProps, any> {
  constructor(props) {
    super(props);
  }

  download = (e) => {
    e.stopPropagation();
    window.location.href = window.location.protocol +
      "//" +
      window.location.host +
      "/pressroom/lexus/api/pressrelease/DownloadFile?id=" +
      this.props.itemId;
  }

  render() {
    return (
      <div className={this.props.className}>
        <a onClick={this.download}>
          <PRIcon className="download">
            <DownloadIcon />
          </PRIcon>
        </a>
      </div>
    );
  }
}

export default DownloadItem;
