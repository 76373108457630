import React from 'react';
import PRDialog from '../../PageContent/PRDialog/PRDialog';
import Form from '../../PageContent/Form/Form';
import FileDrop from '../../PageContent/Form/Fields/FileDrop/FileDrop';
import PRTextField from '../../PageContent/Form/Fields/PRTextField/PRTextField';
import { Categories } from '../../Categories/Categories';
import './MediaForm.scss';
import { PressRoomApi } from '../../../services/pressRoomApi';
import Constants from '../../constants';
import { DateTime } from '../../PageContent/Date/DateTime';
import moment from 'moment';

interface IMediaFormProps {
    onChange?: Function;
    editMode?: boolean;
    itemId?: string;
    type: string;
    onClose?: Function;
    onSave?: Function;
}

interface IMediaFormState {
    openUploadDialog: boolean;
    uploading: boolean;
    loading: boolean;
    itemData: any;
}

export class MediaForm extends React.Component<IMediaFormProps, IMediaFormState> {
    constructor(props: IMediaFormProps) {
        super(props);
        this.state = {
            openUploadDialog: false,
            uploading: false,
            loading: true,
            itemData: null
        }
    }

    componentDidMount() {
        if (this.props.editMode && this.props.itemId) {
            this.getMediaItem(this.props.itemId);
        }
        else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps: IMediaFormProps) {
        if (this.props.editMode && this.props.itemId && (this.props.editMode !== prevProps.editMode || this.props.itemId !== prevProps.itemId)) {
            this.getMediaItem(this.props.itemId);
        }
    }

    getMediaItem = async (id: string) => {
        this.setState({ loading: true });
        let api = new PressRoomApi();
        let result = await api.getMedia(id);
        if (result.success) {
            this.setState({ openUploadDialog: true, itemData: result.data, loading: false });
        }
    }

    render() {
        let className = this.state.uploading ? 'uploading' : '';
        let self = this;
        return (
            <div>
                <div className="main-container" onClick={() => { this.setState({ openUploadDialog: true }) }}>
                    {this.props.children}
                </div>

                <PRDialog open={this.state.openUploadDialog} onClose={this.onClose}>
                    <div className="download-dialog-header" key="header"><h3>{(this.props.editMode ? 'Edit ' : 'New ') + this.props.type}</h3></div>
                    <div key="content" className={className}>
                        {!this.state.loading && <Form data={{ primaryBtnLabel: (this.props.editMode ? 'Edit ' : 'Add ') + this.props.type, secondaryBtnLabel: 'Cancel' }}
                            onCancel={this.onClose} onSubmit={(data) => this.onFormSubmit.bind(self, data)()}>
                            <DateTime
                                name="publishAt"
                                label="Publish at"
                                selectedDateTime={this.state.itemData ? this.state.itemData.PublishAt : null}
                                clearable={true}
                                disablePast={true}
                            />
                            <FileDrop
                                required
                                type={this.props.type.toLowerCase()}
                                label={this.props.type}
                                name="highResFile"
                                value={this.state.itemData ? this.state.itemData.HighResolutionAssertUrl : null}
                                extension={this.state.itemData ? this.props.type.toLowerCase() + "/" + this.state.itemData.HighResolution.Extension : null}>
                            </FileDrop>
                            <FileDrop
                                required
                                type="image"
                                label={"Thumbnail Image: " + Constants.ImageDimensions.Thumbnail.width + "x" + Constants.ImageDimensions.Thumbnail.height}
                                name="thumbnail"
                                value={this.state.itemData ? this.state.itemData.Thumbnail.ImageUrl : null}>
                            </FileDrop>
                            {this.props.type === "Image" &&
                                <FileDrop
                                    type="image"
                                    label="Low Resolution Image (If not provided, the Thumbnail will be used)"
                                    name="lowResImage"
                                    value={this.state.itemData ? this.state.itemData.LowResolutionAssertUrl : null}>
                                </FileDrop>
                            }
                            <PRTextField required label="Title" name="title" value={this.state.itemData ? this.state.itemData.Title : ''}></PRTextField>
                            <PRTextField maxLength="85" label="Description" name="description" value={this.state.itemData ? this.state.itemData.ShortDescription : ''} error={this.state.itemData && this.state.itemData.ShortDescription && this.state.itemData.ShortDescription.length > 85}></PRTextField>
                            <PRTextField label="Keywords" name="keywords" value={this.state.itemData ? this.state.itemData.Keywords : ''}></PRTextField>
                            <Categories name="category" selectedCategory={this.state.itemData ? this.state.itemData.CategoryId : null}></Categories>
                        </Form>}
                        {this.state.uploading && <div className="uploading-container">Uploading</div>}
                    </div>
                </PRDialog>
            </div>
        )
    }

    onFormSubmit = async (data) => {
        // Publish date time must be in the future
        if (data.publishAt && moment().isAfter(data.publishAt)) {
            return;
        }

         // Make sure the short description isn't too long
        // data.description is undefined if that field hasn't been touched, so check the existing value.
        // If the field is touched, it's own validation will handle this.
        if (typeof data.description === 'undefined' && this.state.itemData && this.state.itemData.ShortDescription && this.state.itemData.ShortDescription.length > 85) {
            return;
        }

        this.setState({ uploading: true });
        let api = new PressRoomApi();
        let result = this.props.editMode ? await api.updateMedia(data, this.state.itemData) : await api.createMedia(data, this.props.type);
        if (result.success && result.data.Success) {
            this.setState({ uploading: false, openUploadDialog: false });
            if (this.props.onSave) {
                this.props.onSave();
            }
        }
    };

    onClose = () => {
        this.setState({ openUploadDialog: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };
}