import React from 'react';
import PRFilter from '../../PageContent/PRFilter/PRFilter';
import SearchResults from '../../SearchResults/SearchResults';
import PRPagination from '../../PageContent/PRPagination/PRPagination';
import { PressRoomApi } from '../../../services/pressRoomApi';
import PRButton from '../../PageContent/PRButton/PRButton';
import { ItemType } from '../../PageContent/ItemType/ItemType';
import "./PRSearch.scss";
import { FilterHelper } from '../../../services/filterHelper';

interface ISearchState {
    images: Array<any>;
    videos: Array<any>;
    pressReleases: Array<any>;
    pressKits: Array<any>;
}

export default class Search extends React.Component<any, ISearchState> {

    private filters: any = {
        Query: "",
        SortType: "Newest",
        DateFrom: "",
        DateTo: "",
        Page: 1,
        PerPage: 10,
        Type: "All"
    };

    private urlString: Array<string> = [];

    private totalItems: number = 0;

    private isASearch: boolean = false;

    private filterHelper: FilterHelper;

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            videos: [],
            pressReleases: [],
            pressKits: [],
        }

        this.filterHelper = new FilterHelper();
    }

    componentDidMount() {
        this.setUpFilters();
        this.getItems();
    }

    getItems = async () => {
        let api = new PressRoomApi();
        if (this.filters.Type === "All") {
            let imageResponse = await api.searchMedia({ ...this.filters, Type: ["Image"] });
            if (imageResponse.success) {
                this.setState({ images: imageResponse.data.Results });
            }
            let videoResponse = await api.searchMedia({ ...this.filters, Type: ["Video"] });
            if (videoResponse.success) {
                this.setState({ videos: videoResponse.data.Results });
            }
            let pressReleaseResponse = await api.searchMedia({ ...this.filters, Type: ["PressRelease"] });
            if (pressReleaseResponse.success) {
                this.setState({ pressReleases: pressReleaseResponse.data.Results });
            }
            let pressKitResponse = await api.searchMedia({ ...this.filters, Type: ["PressKit"] });
            if (pressKitResponse.success) {
                this.setState({ pressKits: pressKitResponse.data.Results });
            }
        }
        else {
            let response = await api.searchMedia({ ...this.filters, Type: [this.filters.Type] });
            if (response.success) {
                this.totalItems = response.data.TotalCount;
                switch (this.filters.Type) {
                    case "Image":
                        this.setState({ images: response.data.Results, videos: [], pressReleases: [], pressKits: [] });
                        break;
                    case "Video":
                        this.setState({ videos: response.data.Results, images: [], pressReleases: [], pressKits: [] });
                        break;
                    case "PressRelease":
                        this.setState({ pressReleases: response.data.Results, videos: [], images: [], pressKits: [] });
                        break;
                    case "PressKit":
                        this.setState({ pressKits: response.data.Results, videos: [], pressReleases: [], images: [] });
                        break;
                }
            }
        }
    }

    setUpFilters() {
        var kvp = document.location.search.substr(1).split('&');

        var filters = {
            Page: null,
            PerPage: 10,
            SortType: "Newest",
            Type: null
        };
        var tempFilters = {};

        var i = kvp.length; var x; while (i--) {
            x = kvp[i].split('=');

            if (x[0] == encodeURI('Query') ||
                x[0] == encodeURI('DateFrom') ||
                x[0] == encodeURI('DateTo') ||
                x[0] == encodeURI('Page') ||
                x[0] == encodeURI('Type') ||
                x[0] == encodeURI('CategoryId')) {
                filters[decodeURI(x[0])] = decodeURI(x[1]);
            }

            if (x[0] == encodeURI('DateFrom') || x[0] == encodeURI('DateTo') || x[0] == encodeURI('CategoryId') || x[0] == encodeURI('Query')) {
                tempFilters[decodeURI(x[0])] = decodeURI(x[1]);
            }

            if (x[0] == encodeURI('Search')) {
                this.isASearch = true;
            }
        }

        if (filters.Page === null || filters.Type === null) {

            if (filters.Page === null) {
                filters.Page = 1;
                kvp = this.filterHelper.addToUrlString(kvp, 'Page', 1);
            }

            if (filters.Type === null) {
                filters.Type = 'All';
                kvp = this.filterHelper.addToUrlString(kvp, 'Type', 'All');
            }

            this.filterHelper.applyUrlStringToUrl(kvp, filters);
        }

        if ((typeof filters.Page) === 'string') {
            filters.Page = parseInt(filters.Page);
        }

        this.urlString = kvp;
        filters.PerPage = filters.Type === "All" ? 10 : 16;
        this.filters = filters;
    }

    onFilterChange = (filters: any) => {
        filters.PerPage = filters.Type === "All" ? 10 : 16;

        let urlString = this.urlString;
        for (var filterKey in filters) {
            if (filters.hasOwnProperty(filterKey) && filterKey !== 'PerPage' && filterKey !== 'SortType') {
                urlString = this.filterHelper.addToUrlString(
                    urlString,
                    filterKey,
                    filters[filterKey]
                );
            }
        }

        this.filters = filters;
        this.urlString = urlString;

        this.filterHelper.applyUrlStringToUrl(urlString, this.filters);
        this.getItems();
    };

    onPageChange = currentPage => {
        let filters = { ...this.filters };
        filters.Page = currentPage;
        this.onFilterChange(filters);
    };

    onViewAll = (type: string) => {
        let filters = { ...this.filters };
        filters.Type = type;
        this.onFilterChange(filters);
    };

    renderImageSection = () => {
        if (this.state.images && this.state.images.length) {
            return (
                <div className="PR-section">
                    <div className="PR-section-container">
                        <h2 className="section-header center">Images</h2>
                        <SearchResults
                            items={this.state.images}
                            includePreview
                            actions={{ addToCart: true, download: true }}
                        />
                        {this.filters.Type === "All" ?
                            <PRButton className="view-all" data={{ type: "primary" }} onClick={() => this.onViewAll("Image")}>View All</PRButton> :
                            <PRPagination
                                onPageChange={this.onPageChange}
                                data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
                            </PRPagination>}
                    </div>
                </div>
            );
        }
    }

    renderVideoSection = () => {
        if (this.state.videos && this.state.videos.length) {
            return (
                <div className="PR-section">
                    <div className="PR-section-container">
                        <h2 className="section-header center">Videos</h2>
                        <SearchResults
                            items={this.state.videos}
                            includePreview
                            actions={{ addToCart: true, download: true }}
                        />
                        {this.filters.Type === "All" ?
                            <PRButton className="view-all" data={{ type: "primary" }} onClick={() => this.onViewAll("Video")}>View All</PRButton> :
                            <PRPagination
                                onPageChange={this.onPageChange}
                                data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
                            </PRPagination>}
                    </div>
                </div>
            );
        }
    }

    renderPressReleaseSection = () => {
        if (this.state.pressReleases && this.state.pressReleases.length) {
            return (
                <div className="PR-section">
                    <div className="PR-section-container">
                        <h2 className="section-header center">Press Releases</h2>
                        <SearchResults
                            items={this.state.pressReleases}
                            actions={{ addToCart: true, download: true }}
                        />
                        {this.filters.Type === "All" ?
                            <PRButton className="view-all" data={{ type: "primary" }} onClick={() => this.onViewAll("PressRelease")}>View All</PRButton> :
                            <PRPagination
                                onPageChange={this.onPageChange}
                                data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
                            </PRPagination>}
                    </div>
                </div>
            );
        }
    }

    renderPressKitSection = () => {
        if (this.state.pressKits && this.state.pressKits.length) {
            return (
                <div className="PR-section">
                    <div className="PR-section-container">
                        <h2 className="section-header center">Press Kits</h2>
                        <SearchResults
                            items={this.state.pressKits}
                            actions={{ addToCart: true, download: true }}
                        />
                        {this.filters.Type === "All" ?
                            <PRButton className="view-all" data={{ type: "primary" }} onClick={() => this.onViewAll("PressKit")}>View All</PRButton> :
                            <PRPagination
                                onPageChange={this.onPageChange}
                                data={{ current: this.filters.Page, totalItems: this.totalItems, itemsPerPage: this.filters.PerPage }}>
                            </PRPagination>}
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="media-library-container">
                <PRFilter
                    label="Media Library"
                    filters={this.filters}
                    showAllOption
                    showTypes={this.isASearch}
                    onFilterChange={this.onFilterChange}
                />
                {this.renderPressReleaseSection()}
                {this.renderImageSection()}
                {this.renderVideoSection()}
                {this.renderPressKitSection()}
            </div>
        )
    }
}