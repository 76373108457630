import React from "react";

export interface PRCarouselItemProps {
  data: {
    Image: string;
    Label: string;
    Url?: string;
    Description: string;
  };
}

export default class PRCarouselItem extends React.Component<PRCarouselItemProps> {
  render() {
    return (
      <div className="pr-slide-container">
        <div className="pr-slide">
          <a className="pr-slide-link" href={this.props.data.Url}>
            <div className="pr-slide-label">{this.props.data.Label}</div>
            <div className="pr-slide-image-container">
              <img className="pr-slide-image" src={this.props.data.Image} />
            </div>
            <div className="pr-slide-description">{this.props.data.Description}</div>
          </a>
        </div>
      </div>
    );
  }
}
