import React from "react";
import "./PRButton.scss";

export interface PRButtonProps {
  data: {
    type?: string;
    size?: string;
      link?: string;
      newtab?: boolean;
    style?: {};
  };
  onClick?: Function;
  className?: string;
}

export default class PRButton extends React.Component<PRButtonProps, {}> {
  public static defaultProps: Partial<PRButtonProps> = {
    data: {
      type: "secondary",
      size: "",
      link: ""
    }
  };

  render() {
    const { type, size, link, newtab } = this.props.data;
    const buttonType = type ? `prButton-${type}` : "";
    const buttonSizeClass = size === "small" ? "prButton-small" : "";
     const buttonClassName = `prButton ${buttonType} ${buttonSizeClass}` + this.props.className;

    return (
      <a
        style={{ ...this.props.data.style }}
        onClick={e => {
          this.props.onClick ? this.props.onClick(e) : null;
        }}
        className={buttonClassName}
            href={link}
            target={newtab ? "_blank" : "_self"}
      >
        <span>{this.props.children}</span>
      </a>
    );
  }
}
