import React from "react";
import { PRIcon } from "../../PRIcon/PRIcon";
import withWidth from "@material-ui/core/withWidth";
import "./PRPagination.scss";
import { LeftArrowIcon, RightArrowIcon } from "../../PRIcon/Icons";

interface PRPaginationProps {
  data?: {
    current: number;
    totalItems: number;
    itemsPerPage: number;
  };
  width?: string;
  onPageChange: Function;
}

interface PRPaginationState {
  currentPage: number;
}

class PRPagination extends React.Component<PRPaginationProps, PRPaginationState> {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.data.current
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.current !== this.props.data.current) {
      this.setState({ currentPage: this.props.data.current });
    }
  }

  formatNumber = number => {
    return number < 10 ? "0" + number : "" + number;
  };

  onPageChange = number => {
    if (number < 1) return;

    let totalPages = Math.ceil(this.props.data.totalItems / this.props.data.itemsPerPage);
    if (number > totalPages) return;

    this.setState({ currentPage: number });
    this.props.onPageChange(number);
  };

  render() {
    // Don't show pagination if there aren't even any items.
    if (!this.props.data.totalItems) {
      return null;
    }

    let current = this.state.currentPage;
    let totalItems = this.props.data.totalItems;
    let itemsPerPage = this.props.data.itemsPerPage;

    let totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [{ pageNumber: 1, current: current === 1, disabled: false }];
    let pageNumber = 2;
    let num = this.props.width === "xs" ? 1 : 2;

    if (totalPages > 1) {
      if (current < num + 1 && pageNumber < num + 1) {
        for (let i = 0; i < Math.min(num, totalPages - 1); i++) {
          let page = {
            pageNumber: pageNumber,
            current: current === pageNumber,
            disabled: false
          };
          pageNumber = pageNumber + 1;
          pages.push(page);
        }
        if (totalPages > num +1 && totalPages!== pageNumber) { // to make sure the next page is not the least one
          pages.push({ pageNumber: -1, current: false, disabled: true });
        }
      } else if (current > totalPages - num ) {
        pageNumber = totalPages - num;
        if(pageNumber != num) // if there is no gap and there is only 2 *num pages
         {pages.push({ pageNumber: -1, current: false, disabled: true }); }
        for (let i = 0; i < num; i++) {
          let page = {
            pageNumber: pageNumber,
            current: current === pageNumber,
            disabled: false
          };
          pageNumber = pageNumber + 1;
          pages.push(page);
        }
      } else if (current >= num + 1 && current <= totalPages - num) {
        pages.push({ pageNumber: -1, current: false, disabled: true });
        pageNumber = current;
        pages.push({ pageNumber: pageNumber, current: true, disabled: false });
        if (this.props.width !== "xs") {
          pageNumber = pageNumber + 1;
          pages.push({
            pageNumber: pageNumber,
            current: false,
            disabled: false
          });
        }
        if(pageNumber != totalPages -1 ) 
        {
          pages.push({ pageNumber: -1, current: false, disabled: true }); 
        }
      }

      if (totalPages > num +1) {
        pages.push({
          pageNumber: totalPages,
          current: current === totalPages,
          disabled: false
        });
      }
    }

    return (
      <div className="pr-pagination">
         <button className={`page-number ${(this.state.currentPage === 1 ? "disabled" : "")}`}
          onClick={() => { this.onPageChange(1); }} >
          <PRIcon>
            <LeftArrowIcon />
          </PRIcon>
        </button>
        <button className={`page-number ${(this.state.currentPage === 1 ? "disabled" : "")}`}
          onClick={() => { this.onPageChange(this.state.currentPage - 1); }} >
          <PRIcon>
            <LeftArrowIcon />
          </PRIcon>
        </button>
       {
       pages.map((page, $index) => {
            let className =
              "page-number" +
              (page.current ? " current" : "") +
              (page.disabled ? " disabled" : "");
            return (
              <div key={$index}>
                {page.disabled ? (
                  <div className={className}> ... </div>
                ) : (
                    <button
                      className={className}
                      onClick={() => {
                        this.onPageChange(page.pageNumber);
                      }}
                    >
                      {this.formatNumber(page.pageNumber)}
                    </button>
                  )}
              </div>
            );
          }
        )}
        <button className={`page-number ${(this.state.currentPage === totalPages ? "disabled" : "")}`}
          onClick={() => { this.onPageChange(this.state.currentPage + 1); }}>
          <PRIcon>
            <RightArrowIcon />
          </PRIcon>
        </button>
        <button className={`page-number ${(this.state.currentPage === totalPages ? "disabled" : "")}`}
          onClick={() => { this.onPageChange(totalPages); }}>
          <PRIcon>
            <RightArrowIcon />
          </PRIcon>
        </button>
      </div>
    );
  }
}

export default withWidth()(PRPagination);
