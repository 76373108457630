import React from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import './PRBanner.scss';
import objectFitImages from 'object-fit-images';

export interface PRBannerProps {
    data: {
        BannerImage?: string;
        HeaderText: string;
        SubHeader?: string;
        FlagLabel?: string;
    };
    width?: string;
    isEditing?: boolean;
}

class PRBanner extends React.Component<PRBannerProps, {}> {
    private bannerElement: HTMLDivElement;

    constructor(props) {
        super(props);
    }

    static propTypes = {
        width: PropTypes.string.isRequired
    };

    componentDidMount() {
        let images = this.bannerElement.querySelectorAll("img");
        if (images && images.length) {
            objectFitImages(images);
        }
    }

    render() {
        let bannerClass = "banner-container" + (this.props.data.BannerImage ? "" : " no-bg-img");
        let bannerHeader = "banner-header" + (this.props.data.SubHeader ? " has-subheader" : "");

        if (this.props.data.BannerImage || this.props.isEditing) {
            var div = document.createElement('div');
            div.innerHTML = this.props.data.BannerImage.trim();
            div.children[0].setAttribute('width', 'unset');
            div.children[0].setAttribute('height', 'unset');
        }

        return (
            <div className="pr-banner" ref={banner => (this.bannerElement = banner)}>
                <div className={bannerClass}>
                    {(this.props.data.BannerImage || this.props.isEditing) && <div className="banner-image" dangerouslySetInnerHTML={{ __html: div.innerHTML }}></div>}
                    {this.props.width !== 'xs' && this.props.data.HeaderText &&
                        <div className="banner-header-container">
                            <div className={bannerHeader}>
                                {this.props.data.BannerImage && this.props.data.FlagLabel && <div className="banner-header-label">{this.props.data.FlagLabel}</div>}
                                <h1 className="light" dangerouslySetInnerHTML={{ __html: this.props.data.HeaderText }}></h1>
                                {this.props.data.SubHeader && <div className="banner-subheader" dangerouslySetInnerHTML={{ __html: this.props.data.SubHeader }}></div>}
                            </div>
                        </div>
                    }
                </div>
                {this.props.width === 'xs' && this.props.data.HeaderText &&
                    <div className="banner-header-container">
                        <div className="banner-header">
                            {this.props.data.BannerImage && this.props.data.FlagLabel && <div className="banner-header-label">{this.props.data.FlagLabel}</div>}
                            <h1 className="light" dangerouslySetInnerHTML={{ __html: this.props.data.HeaderText }}></h1>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default withWidth()(PRBanner);